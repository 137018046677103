<template>
    <img v-if="company" :src="displayLogo" :height="companyLogo ? '60px' : '32px'" />
</template>

<script>
export default {
    props: {
        company: {
            type: Object,
        },
        defaultLogo: {
            type: String,
            default: '/images/logo-blue.svg',
        },
    },

    computed: {
        displayLogo() {
            return this.companyLogo || this.defaultLogo
        },

        companyLogo() {
            return this.company.logo_thumb || this.company.logo_url
        },
    },
}
</script>