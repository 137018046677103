<template>
    <div class="form-group" v-if="!item.workflow_step_id">
        <label class="form-label">{{ $t('files.move_to_another_file') }}</label>

        <div class="form-autocomplete">
            <DebounceInput v-model="search" :placeholder="$t('files.move_to_another_file')" clearable searchIcon />

            <div v-if="searching" class="menu" v-click-outside="stopSearching">
                <div v-for="file in files" class="menu-item">
                    <a href="#" @click.prevent="setNewFile(file)">{{ file.scan_code }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],

    data() {
        return {
            files: [],
            search: '',
            searching: false,
        }
    },

    watch: {
        async search(search) {
            if (!search) {
                this.searching = false
                this.item.moveToFile = null
                return
            }

            this.searching = true

            const { data } = await this.$axios.get('search', { params: { query: search, open: true, limit: 10 } })

            this.files = data
        }
    },

    methods: {
        stopSearching() {
            this.searching = false
        },

        setNewFile(file) {
            this.search = file.scan_code
            this.item.moveToFile = file
            setTimeout(() => { this.searching = false })
        },
    },
}
</script>
