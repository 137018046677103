<template>
    <div :class="{ 'flex-space': field.type === 'checkbox' }">
        <label v-if="label" :for="field.id" class="form-label d-inline-block" :class="{ 'text-bold': bold }">
            <span>{{ label }}</span>
            <span v-if="required" class="text-error ml-1">*</span>
        </label>

        <textarea v-if="field.type === 'text'" v-model="value" class="form-input" maxlength="4000" rows="3"
            :placeholder="label" :required="required"></textarea>

        <label v-if="field.type === 'checkbox'" class="form-checkbox">
            <input type="checkbox" v-model="value" :true-value="1" :false-value="0" :required="required"
                :id="field.id" />
            <i class="form-icon"></i>
        </label>

        <MultiSelect v-else-if="field.type === 'single_select'" v-model="value" :options="field.options.choices"
            :multiple="field.options.multi" :placeholder="label" :required="required">
        </MultiSelect>

        <input v-else-if="field.type === 'number'" v-model.number="value" type="number" class="form-input"
            :step="1 / 10 ** field.options.numdecimals" :placeholder="label" :required="required" />

        <template v-else-if="field.type === 'date'">
            <DatePicker v-if="field.options.mode === 'date'" v-model="value" format="yyyy-MM-dd" model-type="yyyy-MM-dd"
                text-input :placeholder="label" :required="required">
            </DatePicker>
            <DatePicker v-else-if="field.options.mode === 'datetime'" v-model="value" format="yyyy-MM-dd HH:mm"
                model-type="yyyy-MM-dd HH:mm" time-picker-inline :enable-time-picker="true" text-input
                :placeholder="label" :required="required">
            </DatePicker>
            <DatePicker v-else-if="field.options.mode === 'time'" v-model="value" format="HH:mm" model-type="HH:mm"
                time-picker text-input :placeholder="label" :required="required">
            </DatePicker>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        field: Object,
        label: String,
        bold: Boolean,
        required: Boolean,
        modelValue: [String, Number, Array, Date],
    },

    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>
