<template>
    <slot :hasChanges="hasChanges" :cancel="cancel"></slot>
</template>

<script setup>
import { ref, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import dialog from '~/plugins/dialog.js'
import axios from '~/plugins/axios.js'
import i18n from '~/plugins/i18n.js'

const model = defineModel()
const cache = ref(model.value)
const hasChanges = ref(false)

const init = () => {
    hasChanges.value = false
    cache.value = JSON.parse(JSON.stringify(model.value))
}

const cancel = () => {
    hasChanges.value = false
    model.value = JSON.parse(JSON.stringify(cache.value))
}

defineExpose({ init })

watch(() => model.value, (newValue, oldValue) => {
    const oldKeys = Object.keys(oldValue || {}).length
    const newKeys = Object.keys(newValue || {}).length

    // structure changed, reinitialize
    if (newKeys > oldKeys) return init()

    hasChanges.value = JSON.stringify(model.value) !== JSON.stringify(cache.value)
}, { deep: true })

onBeforeRouteLeave(async (to) => {
    // always allow logging out
    if (to.name === 'login') return

    // no changes, no dialog
    if (!hasChanges.value) return

    // show dialog to proceed & ignore changes
    const shouldProceed = await dialog.confirm(null, {
        title: i18n.global.t('main.unsaved_changes'),
        buttonText: i18n.global.t('main.discard_changes'),
    })

    if (shouldProceed) {
        // after ignoring unsaved changes we clear the cache
        // to prevent the user to see previously cached changes
        axios.storage.clear()
    }

    return shouldProceed
})
</script>