<template>
    <Modal :title="$t('files.add_field_to_file')" @close="back()">
        <div class="form-group">
            <FieldsSelect v-model="fieldSelected"></FieldsSelect>
        </div>

        <div class="form-group">
            <FileFieldsModel v-if="fieldSelected" :field="fieldSelected" v-model="fieldSelected.value" />
        </div>

        <div v-if="canUpdateExistingValues" class="form-group">
            <label class="form-checkbox">
                <input v-model="updateExistingValues" type="checkbox" />
                <i class="form-icon"></i>
                <span>{{ $t('files.update_values_of_existing_fields') }}</span>
            </label>
        </div>

        <template #footer>
            <SaveButton @click="save()" :disabled="!fieldSelected">{{ $t('main.create') }}</SaveButton>
            <button @click="back()" class="btn ml-2">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>

<script>
import FileFieldsModel from '~/components/files/FileFieldsModel.vue'
import FieldsSelect from '~/components/files/fields/FieldsSelect.vue'

export default {
    props: { file: Object },

    components: { FileFieldsModel, FieldsSelect },

    data() {
        return {
            fieldSelected: null,
            updateExistingValues: false,
            ids: this.$route.params.id ? [this.$route.params.id] : this.$route.query.files,
        }
    },

    computed: {
        canUpdateExistingValues() {
            // This check is only relevant on a single file page (where we have file prop)
            // When no file prop is passed (index page), we always show the option to update existing values
            if (!this.file) return true
            if (!this.fieldSelected) return false

            return this.file.fields.some((field) => field.tag_id === this.fieldSelected.id)
        },
    },

    methods: {
        back() {
            this.$route.params.id ?
                this.$router.push({ name: 'files.show' }) :
                this.$router.push({ name: 'files' })
        },

        async save() {
            await this.$axios.post('files_fields', {
                files: this.ids,
                field_id: this.fieldSelected.id,
                field_value: this.fieldSelected.value || '',
                update_existing: this.updateExistingValues,
            })

            this.$emit('refresh')
            this.back()
        },
    },
}
</script>
