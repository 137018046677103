<template>
    <div class="counters">
        <router-link v-for="counter in counters" :style="{ color: counter.color }" :to="getRoute(counter)">
            <span v-if="counter.loading" class="loading"></span>
            <i class="far fa-2x" :class="counter.icon">
                <div class="counter-background"></div>
            </i>
            <h4 class="text-bold mt-4">{{ counter.count }}</h4>
            <div v-if="counter.params">{{ counter.params.title }}</div>
            <div v-else>{{ $t(`profile.counters_${counter.counter}`) }}</div>
        </router-link>
    </div>
</template>

<script>
const ROUTES = {
    forms: { name: 'forms' },
    shares: { name: 'sharing.out' },
    damages: { name: 'files', query: { contains_damage: true } },
    files: { name: 'files', query: { sort: 'created_at', by: 'desc' } },
    files_with_damages: { name: 'files', query: { contains_damage: true } },
    uploads: { name: 'files', query: { sort: 'recent_snap_id', by: 'desc' } },
}

export default {
    data() {
        return {
            startDate: this.getStartDate(),
            counters: this.$store.state.countersConfig
        }
    },

    async mounted() {
        await Promise.all(this.counters.map(this.fetchCounter))
        this.$store.dispatch('updateSettings', { countersConfig: this.counters })
    },

    methods: {
        async fetchCounter(counter) {
            counter.loading = true

            const params = { ...counter.params, start_date: this.startDate }

            try {
                const { data: count } = await this.$axios.get(`statistics/counters/${counter.counter}`, { params })
                counter.count = parseInt(count)
            } catch { }

            counter.loading = false
        },

        getRoute(counter) {
            const formId = counter.params?.id

            if (formId) return { name: 'forms.show', params: { id: formId } }

            return ROUTES[counter.counter]
        },

        getStartDate() {
            const preference = this.$store.state.preferences.start_date

            if (preference === 'all_time') return null

            const DATES = { today: 0, last_7_days: 7, last_30_days: 30 }

            const date = new Date()
            date.setHours(0, 0, 0, 0)
            date.setDate(date.getDate() - DATES[preference])

            return date.toISOString()
        },
    },
}
</script>
