<template>
    <div class="data-table">
        <div class="context-bar">
            <div class="context-left">
                <Dropdown icon v-if="selectable" :disabled="!selectedItems.length">
                    <template #toggle>{{ $t('main.with_selected', selectedItems.length) }}</template>
                    <slot name="with_selected_ids" :ids="selectedItems.map(item => item.id)"></slot>
                </Dropdown>

                <slot name="context-left"></slot>
            </div>
            <div class="context-right" v-if="hasFilters">
                <ResponsiveFilters :loading="loading" :paginator="paginator" :params="transformedParameters"
                    @clearParameters="clearParameters">
                    <slot name="context-right"></slot>
                </ResponsiveFilters>
            </div>
        </div>

        <div class="table-wrapper" :class="`table-${endpoint}`">
            <table class="table" v-if="paginator.data.length">
                <thead>
                    <tr>
                        <th v-if="selectable">
                            <label class="form-checkbox">
                                <input type="checkbox" v-model="selectedAll" />
                                <i class="form-icon"></i>
                            </label>
                        </th>
                        <th v-for="column in columns">
                            <div class="th-cell" @click="updateSort(column)"
                                :class="[{ sortable: column.sortable }, sortBy === column.name ? sortDir : '']">
                                <span>{{ $te(column.th) ? $t(column.th) : column.th }}</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, index) in paginator.data">
                        <tr :data-no-access="filesEditableUntil < item.created_at ? $t('table.trial_expired') : null">
                            <td v-if="selectable" class="min">
                                <label class="form-checkbox">
                                    <input type="checkbox" v-model="item.selected" />
                                    <i class="form-icon"></i>
                                </label>
                            </td>
                            <td v-for="column in columns" :class="[column.class, { min: column.min }]">
                                <slot :name="`column(${column.name})`" :item="paginator.data[index]"
                                    :value="paginator.data[index][column.name]"></slot>
                            </td>
                        </tr>
                        <tr v-if="item.expanded" class="row-expanded">
                            <td colspan="100%">
                                <slot name="expanded" :item="paginator.data[index]"></slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <Pagination v-if="paginator.to" :paginator="paginator" :limit="limit" @updatePage="updatePage"
            @updateLimit="updateLimit">
        </Pagination>

        <Spinner v-if="loading"></Spinner>

        <NoResults v-else-if="!paginator.data.length" :params="transformedParameters" @removeParameter="removeParameter"
            @clearParameters="clearParameters"></NoResults>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoResults from './NoResults.vue'
import Pagination from './Pagination.vue'
import ResponsiveFilters from './ResponsiveFilters.vue'
import { getRouteIndexName } from '~/helpers/router.js'

export default {
    components: { NoResults, Pagination, ResponsiveFilters },

    props: {
        endpoint: {
            type: String,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        parameters: {
            type: Object,
            default: () => ({}),
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        ignoreUrlParameters: {
            type: Array,
            default: () => [],
        },
        sort: {
            type: String,
            default: undefined,
        },
        by: {
            type: String,
            default: undefined,
        },
        poll: {
            type: Boolean,
            default: false,
        },
        hasFilters: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            timer: null,
            loading: false,
            selectedAll: false,
            paginator: { data: [] },

            page: this.$route.query.page || 1,
            sortBy: this.$route.query.sort || this.sort,
            sortDir: this.$route.query.by || this.by || (this.sort ? 'desc' : undefined),
            limit: this.$route.query.limit || this.$store.state.indexLimits[getRouteIndexName()] || 10,
        }
    },

    computed: {
        ...mapGetters(['filesEditableUntil']),

        selectedItems() {
            return this.paginator.data.filter(i => i.selected)
        },

        transformedParameters() {
            const params = []

            for (let key in this.parameters) {
                let value = this.parameters[key]

                if (!value || !value.toString()) continue

                if (key === 'fields') {
                    const fields = value.map(f => ({ key, value: `${f.name} ${f.operator} ${f.value}`, f }))
                    params.push(...fields.reverse())
                    continue
                }

                if (key === 'date_range' && Array.isArray(value)) {
                    value = value.map(v => dayjs(v).format('L')).join(' ~ ')
                }

                params.push({ key, value })
            }

            return params || []
        },
    },

    mounted() {
        this.fetchData()

        if (!this.poll) return

        this.timer = setInterval(() => {
            this.$axios.storage.clear()
            this.fetchData(false, false)
        }, 10000)
    },

    beforeUnmount() {
        clearInterval(this.timer)
    },

    watch: {
        parameters() {
            this.page = 1
            this.fetchData(true)
        },

        selectedAll() {
            for (let i = 0; i < this.paginator.data.length; i++) {
                const item = this.paginator.data[i]

                if (this.filesEditableUntil < item.created_at) continue

                item.selected = this.selectedAll
            }
        },
    },

    methods: {
        async fetchData(updateUrl = false, loadingIndicator = true) {
            const params = {
                page: parseInt(this.page),
                sort: this.sortBy,
                by: this.sortDir, // todo: for consistency we should
                order: this.sortDir, // remove BY or ORDER (on backend)
                limit: this.limit,
                ...this.parameters,
            }

            if (updateUrl) this.replaceUrlWithParams(params)

            this.loading = loadingIndicator

            try {
                const { data: paginator } = await this.$axios.get(this.endpoint, { params })

                window.scroll({ top: 0, behavior: 'smooth' })

                this.paginator = paginator
                this.selectedAll = false
                return true
            } catch {
                return false
            } finally {
                this.loading = false
            }
        },

        replaceUrlWithParams(params) {
            let query = JSON.parse(JSON.stringify(params))

            this.ignoreUrlParameters.forEach(p => delete query[p])
            this.$router.replace({ name: this.$route.name, query })
        },

        updatePage(page) {
            this.page = page
            this.fetchData(true)
        },

        async updateLimit(limit) {
            this.page = 1
            this.limit = limit

            if (!await this.fetchData(true)) return

            this.$store.commit('UPDATE_INDEX_LIMITS', { key: this.$route.name, limit })
        },

        updateSort(column) {
            if (!column.sortable) return

            const sortBy = column.name

            if (this.sortBy === sortBy) {
                if (this.sortDir === 'desc') {
                    this.sortDir = 'asc'
                } else {
                    this.sortBy = undefined
                    this.sortDir = undefined
                }
            } else {
                this.sortBy = sortBy
                this.sortDir = 'desc'
            }

            this.page = 1
            this.fetchData(true)
        },

        removeParameter(param) {
            if (!this.$parent[param.key]) return

            if (param.key === 'fields') {
                const field = this.$parent['fields'].find(f => f == param.f)
                field.enabled = false
            } else {
                this.$parent[param.key] = undefined
            }
        },

        clearParameters() {
            this.transformedParameters.forEach(this.removeParameter)
        },
    },
}
</script>
