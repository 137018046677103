<template>
    <div>
        <div class="timeline all" v-viewer="options">
            <template v-for="item in uploads" :key="item.upload_type + item.id">
                <component :is="TYPE_TO_COMPONENT[item.upload_type]" :item="item" showReference
                    :data-no-access="filesEditableUntil < item.created_at ? $t('table.trial_expired') : null"
                    @edit="onEdit" @share="onShare" @delete="onDelete" :class="item.upload_type">
                </component>
            </template>
        </div>

        <TimelineItemUploadEdit v-if="itemEdit" :item="itemEdit" @update="updateEdit" @close="itemEdit = null">
        </TimelineItemUploadEdit>

        <Modal v-if="showOnMap" @close="$router.push({ query: $route.query })" class="modal-larger">
            <MapsView :snap_id="showOnMap" :uploads="uploads" style="height: 50vh"></MapsView>
        </Modal>
    </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'

import { mapGetters } from 'vuex'
import MapsView from '~/components/MapsViewAsync.vue'
import TimelineItemForm from './TimelineItemForm.vue'
import TimelineItemUpload from './TimelineItemUpload.vue'
import TimelineItemUploadEdit from './TimelineItemUploadEdit.vue'
import TimelineItemAttachment from './TimelineItemAttachment.vue'

export default {
    components: {
        MapsView,
        TimelineItemForm,
        TimelineItemUpload,
        TimelineItemUploadEdit,
        TimelineItemAttachment,
    },

    props: ['uploads'],

    directives: { viewer: viewer() },

    data() {
        return {
            itemIdx: -1,
            itemEdit: null,
            showOnMap: null,

            TYPE_TO_COMPONENT: {
                'snap': 'TimelineItemUpload',
                'seal': 'TimelineItemUpload',
                'video': 'TimelineItemUpload',
                'document': 'TimelineItemUpload',
                'barcodeTag': 'TimelineItemUpload',
                'containerTag': 'TimelineItemUpload',
                'serial-shooter': 'TimelineItemUpload',
                'multi_page_document': 'TimelineItemUpload',
                'licensePlateScanner': 'TimelineItemUpload',
                'attachment': 'TimelineItemAttachment',
            },

            options: {
                button: false,
                transition: false,
                url: 'data-source',
                viewed: this.onView,
            },
        }
    },

    watch: {
        '$route.hash': {
            immediate: true,
            handler(hash) {
                const snapId = hash.split('#map-')[1]
                const snapToShow = this.uploads.find(u => u.id == snapId)

                this.showOnMap = snapToShow ? snapToShow.id : null
            },
        },
    },

    computed: {
        ...mapGetters(['filesEditableUntil']),
    },

    created() {
        this.uploads.sort((a, b) => new Date(b.scan_date_time) - new Date(a.scan_date_time))
    },

    methods: {
        onView(e) {
            const viewer = e.target.viewer
            const thumbElement = viewer.images[viewer.index]

            // Only apply rotation hack if thumbnail is loaded
            if (thumbElement.getAttribute('lazy') !== 'loaded') return

            const origIsPortrait = this.isPortrait(viewer.imageData)
            const thumbIsPortrait = this.isPortrait(thumbElement)

            if (origIsPortrait !== thumbIsPortrait) {
                viewer.rotate(90)
            }
        },

        isPortrait(img) {
            const w = img.naturalWidth || img.width
            const h = img.naturalHeight || img.height

            return h > w
        },

        onShare(item) {
            if (item.upload_type === 'form') {
                item.shared = !item.shared
                this.$axios.patch(`submits/${item.id}`, item)
            } else {
                item.include_in_share = !item.include_in_share
                this.$axios.patch(`uploads/${item.id}`, item)
            }
        },

        onEdit(item) {
            this.itemEdit = { ...item } // keep original data and use this for editting
            this.itemIdx = this.uploads.indexOf(item) // on save we update the original
        },

        onDelete(item) {
            this.$dialog.danger(async () => {
                // we only have uploads on dashboard
                await this.$axios.delete(`uploads/${item.id}`)

                this.$toast(this.$root.$t('i.delete', { i: item.upload_type }))

                this.uploads.splice(this.uploads.indexOf(item), 1)
            })
        },

        updateEdit(data) {
            Object.assign(data, this.itemEdit)
            this.uploads[this.itemIdx] = data
            this.itemEdit = null
        },
    },
}
</script>