<template>
    <Modal v-if="showModal" :title="$t('billing.soft_blocked')">
        <p>{{ $t('billing.soft_blocked_message') }}</p>

        <template #footer>
            <div class="flex-space">
                <button class="btn btn-primary" @click="setupSubscription()" v-if="$store.getters.can('Use subscriptions')">
                    {{ $t('auth.setup_subscription') }}
                </button>
                <button class="btn" @click="$store.dispatch('endSession')">{{ $t('profile.sign_out') }}</button>
            </div>
        </template>
    </Modal>
</template>

<script>
export default {
    data: () => ({ showModal: true }),

    watch: {
        $route(to, from) {
            if (!to.path.includes('billing')) {
                this.showModal = true
            }
        },
    },

    methods: {
        setupSubscription() {
            this.showModal = false
            this.$router.push({ name: 'billing' })
        }
    }
}
</script>
