<template>
    <Dropdown icon :disabled="loading">
        <template #toggle>
            <span class="mr-2">
                <i v-if="loading" class="loading mx-2"></i>
                <i v-else class="fas fa-globe"></i>
            </span>
            <span>{{ locales[currentLocale] }}</span>
        </template>

        <div class="menu-item" v-for="(lang, code) in locales" :class="{ active: currentLocale === code }">
            <a href="#" @click.prevent="setLanguage(code)">{{ lang }}</a>
        </div>
    </Dropdown>
</template>

<script>
import { setI18nLanguage } from '~/plugins/i18n.js'

export default {
    props: { save: Boolean },

    data: () => ({
        loading: false,
        locales: window.config.locales,
    }),

    computed: {
        currentLocale() {
            return this.$i18n.locale
        },
    },

    methods: {
        async setLanguage(language) {
            if (this.loading) return

            this.loading = true
            await setI18nLanguage(language)
            this.loading = false

            this.$store.commit('UPDATE_LANGUAGE', { language })
            if (this.save) this.$store.dispatch('updateSettings', { language })
        },
    },
}
</script>
