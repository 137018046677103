<template>
    <div class="device-version" :class="[status, { tooltip }]" :data-tooltip="tooltip">
        <i v-if="tooltip" class="fas fa-circle-exclamation mr-1"></i>
        <span>{{ version }}</span>
    </div>
</template>

<script>
export default {
    props: { status: String, version: String },

    computed: {
        tooltip() {
            if (['outdated', 'unsupported'].includes(this.status)) {
                return this.$t(`devices.${this.status}`)
            }
        }
    }
}
</script>