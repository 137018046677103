<template>
    <CreateIssuePage :title="$t('issues.new_issue_for_reference', { reference: file.scan_code })"
        @close="$router.push({ name: 'files.show' })" :associations="[file]"></CreateIssuePage>
</template>

<script>
import CreateIssuePage from '~/pages/platform/issues/create.vue'
export default {
    props: { file: Object },
    components: { CreateIssuePage }
}
</script>