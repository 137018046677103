<template>
    <Dropdown class="company-dropdown dropdown-right dropdown-big">
        <template #toggle>
            <div class="flex-space">
                <Avatar :initials="user.company.company" class="bg-orange"></Avatar>
                <div class="text-ellipsis m-250 ml-2 hide-xl">{{ user.company.company }}</div>
            </div>
        </template>
        <div class="nav">
            <div class="flex-space mb-2">
                <Avatar :initials="user.name" online></Avatar>
                <div class="ml-2" style="flex: 1">
                    <div class="text-bold">{{ user.name }}</div>
                    <div>{{ user.email }}</div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="nav-item">
                <router-link :to="{ name: 'settings' }" exact>
                    <i class="far fa-fw fa-user"></i>
                    <span>{{ $t('profile.personal_settings') }}</span>
                </router-link>
            </div>
            <div class="nav-item" v-if="can('Use management')">
                <router-link :to="{ name: 'settings.teams' }">
                    <i class="far fa-fw fa-users-medical"></i>
                    <span>{{ $t('profile.teams_devices_users') }}</span>
                </router-link>
            </div>
            <div class="nav-item" v-if="can('Use global settings')">
                <router-link :to="{ name: 'settings.company' }">
                    <i class="far fa-fw fa-building"></i>
                    <span>{{ $t('profile.company_settings') }}</span>
                </router-link>
            </div>
            <div class="nav-item" v-if="can('Use subscriptions')">
                <router-link :to="{ name: 'billing' }">
                    <i class="far fa-fw fa-credit-card"></i>
                    <span>{{ $t('main.billing') }}</span>
                </router-link>
            </div>
            <div class="divider"></div>
            <div class="nav-item">
                <a href="#" @click.prevent="$store.dispatch('endSession')">{{ $t('profile.sign_out') }}</a>
            </div>
        </div>
    </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '~/components/Avatar.vue'

export default {
    props: ['user'],

    computed: mapGetters(['can']),

    components: { Avatar },
}
</script>