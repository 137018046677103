<template>
    <div :data-active="active" @dragenter.prevent="setActive" @dragover.prevent="setActive"
        @dragleave.prevent="setInactive" @drop.prevent="onDrop">
        <slot></slot>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
const emit = defineEmits(['onDrop'])

let active = ref(false)
let inActiveTimeout = null

const onDrop = e => {
    active.value = false

    const files = [...e.dataTransfer.files]
    emit('onDrop', files.filter(file => file.size > 0))
}

const setActive = (e) => {
    active.value = true
    clearTimeout(inActiveTimeout)
}

const setInactive = () => {
    inActiveTimeout = setTimeout(() => {
        active.value = false
    }, 50)
}

const preventDefaults = e => {
    e.preventDefault()
}

onMounted(() => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((event) => {
        document.body.addEventListener(event, preventDefaults)
    })
})

onUnmounted(() => {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((event) => {
        document.body.removeEventListener(event, preventDefaults)
    })
})
</script>