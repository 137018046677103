<template>
    <input v-if="field.type === 'text'" v-model="value" class="form-input" maxlength="255" />

    <input v-else-if="field.type === 'number'" type="number" v-model="value" class="form-input" max="2147483647" />

    <DatePicker v-else-if="field.type === 'date'" v-model="value" format="yyyy-MM-dd" model-type="yyyy-MM-dd"
        text-input>
    </DatePicker>

    <label v-else-if="field.type === 'checkbox'" class="form-checkbox">
        <input type="checkbox" v-model="value" :true-value="1" :false-value="0" />
        <i class="form-icon"></i>
    </label>

    <MultiSelect v-else-if="field.type === 'select'" v-model="value" :options="field.properties.select"
        :clearable="false">
    </MultiSelect>

    <MultiSelect v-else-if="field.type === 'geo_fencing'" v-model="value" label="name" :reduce="country => country.name"
        :options="field.properties.geo_fencing" :clearable="false">
    </MultiSelect>

    <input v-else v-model="value" class="form-input" />
</template>

<script>
export default {
    props: {
        modelValue: [String, Number],
        field: Object,
        autoFocus: Boolean,
    },

    emits: ['update:modelValue'],

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },

    mounted() {
        if (this.autoFocus && this.$el.tagName === 'INPUT') {
            this.$el.focus()
        }
    },
}
</script>
