<template>
    <div class="navbar-background navbar-fixed">
        <div class="navbar-wrapper">
            <header class="navbar" :class="{ 'has-scrolled': scrolled }">
                <section class="navbar-section">
                    <MobileDropdown :user="user"></MobileDropdown>
                    <router-link class="navbar-brand" :to="{ name: 'dashboard' }" aria-label="Dashboard"
                        @click="scrollToTop()"></router-link>
                </section>
                <section class="navbar-section navbar-items">
                    <NavBarItems class="d-flex" :user="user"></NavBarItems>
                </section>
                <section class="navbar-section">
                    <FileSearchDropdown @refresh="$emit('refresh')"></FileSearchDropdown>
                    <CompanyDropdown :user="user"></CompanyDropdown>
                </section>
            </header>
        </div>
    </div>
    <div class="navbar-placeholder"></div>
</template>

<script>
import NavBarItems from './NavBarItems.vue'
import MobileDropdown from './MobileDropdown.vue'
import CompanyDropdown from './CompanyDropdown.vue'
import FileSearchDropdown from '~/components/files/FileSearchDropdown.vue'

export default {
    props: ['user'],
    emits: ['refresh'],

    components: { NavBarItems, MobileDropdown, CompanyDropdown, FileSearchDropdown },

    data: () => ({ scrolled: false, handleScroll: null }),

    mounted() {
        this.handleScroll = () => (this.scrolled = window.scrollY > 80)

        window.addEventListener('scroll', this.handleScroll)
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        scrollToTop() {
            window.scroll({ top: 0, behavior: 'smooth' })
        },
    },
}
</script>
