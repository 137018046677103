<script setup>
import { useStore } from 'vuex'
import { useAttrs, defineAsyncComponent } from 'vue'
import CalendarIcon from './icons/CalendarIcon.vue'

const store = useStore()
const attrs = useAttrs()
const model = defineModel()

const DatePicker = defineAsyncComponent(() => import('./DatePicker.vue'))
</script>

<template>
    <Suspense>
        <DatePicker v-model="model" v-bind="attrs" :dark="store.state.darkMode" />
        <template #fallback>
            <div class="dp__main dp__theme_light">
                <div>
                    <div class="dp__input_wrap">
                        <input class="form-input dp__pointer dp__input dp__input_icon_pad"
                            :placeholder="attrs.placeholder" />
                        <div>
                            <span class="dp__input_icon">
                                <CalendarIcon></CalendarIcon>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Suspense>
</template>