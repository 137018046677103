<template>
	<page_not_found v-if="exception"></page_not_found>
	<Spinner v-else></Spinner>
</template>

<script>
import * as Sentry from '@sentry/vue'
import page_not_found from '~/pages/page_not_found.vue'

export default {
	components: { page_not_found },

	data() {
		return {
			object: {},
			exception: false,
			payload: this.$route.params.payload,
		}
	},

	mounted() {
		try {
			this.object = this.getObjectFromPayload()
			const route = this.getRouteFromObject(this.object)
			this.$router.replace(route || '/')
		} catch (exception) {
			this.exception = true
			Sentry.captureException(exception, { object: this.object, payload: this.payload })
		}
	},

	methods: {
		getObjectFromPayload() {
			const decoded = window.atob(this.payload)
			const json = JSON.parse(decoded)

			return { type: json.type, data: json.data }
		},

		getRouteFromObject(object) {
			const type = object.type
			const data = object.data

			switch (type) {
				case 'user_invite': {
					return { name: 'invited', params: { token: data.token } }
				}
				case 'password_reset': {
					return { name: 'password.reset', params: { token: data.token }, query: { email: data.email } }
				}
				case 'issue': {
					return { name: 'issues.show', params: { issue_number: data.issue_number } }
				}
				case 'workflow_run': {
					return { name: 'files.show', params: { id: data.file_id } }
				}
        case 'file_share': {
          return { name: 'share', params: { token: data.token } }
        }
			}
		},
	}
}
</script>
