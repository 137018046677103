<template>
    <div>
        <ContextBar
            :title="$t('main.teams')"
            :buttonText="$t('context.create_teams')"
            buttonAbility="Create locations"
            buttonRoute="settings.teams.create"
        >
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable
            endpoint="locations"
            :columns="columns"
            :parameters="parameters"
            :ignoreUrlParameters="['counts', 'organisations']"
            sort="updated_at"
            :key="refresh"
        >
            <template #column(location)="{ item }">
                <router-link :to="{ name: 'settings.teams.show', params: { id: item.id } }" class="table-link">
                    {{ item.location }}
                </router-link>
                <small>{{ organisationTree(item) }}</small>
            </template>
            <template #column(lang)="{ value }">{{ locales[value] }}</template>
            <template #column(users_count)="{ item }">
                <router-link :to="{ name: 'settings.users', query: { location_id: item.id } }">
                    <span class="chip chip-lg">
                        <i class="fas fa-user-group"></i>
                        <span class="ml-2">{{ item.users_count }}</span>
                    </span>
                </router-link>
            </template>
            <template #column(devices_count)="{ item }">
                <router-link :to="{ name: 'settings.devices', query: { location_id: item.id } }">
                    <span class="chip chip-lg">
                        <i class="far fa-mobile"></i>
                        <span class="ml-2">{{ item.devices_count }}</span>
                    </span>
                </router-link>
            </template>
            <template #column(updated_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,
            search: this.$route.query.search,

            columns: [
                { name: 'location', th: 'main.name', sortable: true, class: 'mw-250' },
                { name: 'lang', th: 'main.language', sortable: true },
                { name: 'users_count', th: 'main.users', sortable: true },
                { name: 'devices_count', th: 'main.devices', sortable: true },
                { name: 'updated_at', th: 'main.updated', sortable: true },
            ],

            locales: window.config.locales,
            useOrganisations: this.$store.state.user.properties.use_organisations,
        }
    },

    computed: {
        parameters() {
            return {
                counts: true,
                search: this.search || undefined,
                organisations: this.useOrganisations || undefined,
            }
        },
    },

    methods: {
        organisationTree(location) {
            if (location.organisations && location.organisations.length) {
                return location.organisations.map(o => o.value).join(', ')
            }
        },
    },
}
</script>
