<template>
    <div>
        <div v-for="item in list" class="d-inline-block" style="margin-left: -2px">
            <slot name="avatar" :item="item"></slot>
        </div>

        <div v-if="remainingItems.length" class="d-inline-block" style="margin-left: -2px">
            <slot name="remainingItems" :items="remainingItems"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        maxItems: { type: Number, default: 4 },
    },

    computed: {
        list() {
            return this.items.slice(0, this.maxItems)
        },

        remainingItems() {
            if (this.items.length < this.maxItems) return []

            return this.items.slice(this.maxItems - 1, -1)
        }
    }
}
</script>
