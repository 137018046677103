<script setup>
import i18n from '~/plugins/i18n.js'

import { ref } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers } from '@vuelidate/validators'

const model = defineModel()
const props = defineProps({ hasLabel: Boolean, isCurrentPassword: Boolean, placeholder: String })
const showPassword = ref(false)

const passwordRules = (password) => {
    if (false === /\d/.test(password)) return false // Minimum of 1 Number
    if (false === /[A-Z]/.test(password)) return false // Minimum of 1 Uppercase Letter

    return true
}

const baseRules = {
    required: helpers.withMessage('', required)
}

const newPasswordRules = {
    minLength: helpers.withMessage(i18n.global.t('auth.new_password_minlength', { minlength: 8 }), minLength(8)),
    passwordRules: helpers.withMessage(i18n.global.t('auth.new_password_complexity'), passwordRules)
}

// For the isCurrentPassword (eg: the login password field), we only check for the required rule
const rules = props.isCurrentPassword ? baseRules : { ...baseRules, ...newPasswordRules }

const v$ = useVuelidate(rules, model)
</script>

<template>
    <div class="form-group" :class="{ 'has-error': v$.$errors.length }">
        <label v-if="hasLabel" class="form-label" for="password">{{ $t('users.password') }}</label>

        <div class="has-icon-right">
            <input id="password" :type="showPassword ? 'text' : 'password'" v-model="model" class="form-input"
                :autocomplete="isCurrentPassword ? 'current-password' : 'new-password'"
                :placeholder="props.placeholder" />

            <i class="form-icon fas fa-fw c-hand mr-2" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                @click="showPassword = !showPassword"></i>
        </div>

        <div v-for="e in v$.$errors" class="form-input-hint">{{ e.$message }}</div>
    </div>
</template>