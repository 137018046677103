<template>
    <span v-if="ago" :title="dateFormatted">{{ dateFromNow }}</span>
    <span v-else :title="dateFromNow">{{ dateFormatted }}</span>
</template>

<script>
export default {
    props: {
        date: {
            type: String,
        },
        ago: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        dateFromNow() {
            if (!this.date) return

            return dayjs(this.date).fromNow()
        },

        dateFormatted() {
            if (!this.date) return

            return dayjs(this.date).format('YYYY-MM-DD HH:mm:ss')
        }
    }
}
</script>