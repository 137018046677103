<template>
    <MultiSelect v-model="value" label="name" :options="fields" :placeholder="$t('workflows.choose_field')">
        <template #option="{ option }">
            <i class="fas fa-circle" :style="{ color: option.color }"></i>
            <span class="ml-2">{{ option.name }}</span>
        </template>
        <template #selected-option="{ option }">
            <i class="fas fa-circle" :style="{ color: option.color }"></i>
            <span class="ml-1">{{ option.name }}</span>
        </template>
    </MultiSelect>
</template>

<script>

export default {
    props: { modelValue: Object },
    emits: ['update:modelValue'],

    data: () => ({ fields: [] }),

    async mounted() {
        const { data: paginator } = await this.$axios.get('fields', { params: { limit: 200, sort: 'name', by: 'asc' } })
        this.fields = paginator.data
    },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>