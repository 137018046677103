<template>
    <page_not_found v-if="exception"></page_not_found>
    <Spinner v-else></Spinner>
</template>

<script>
import page_not_found from '~/pages/page_not_found.vue'

export default {
    components: { page_not_found },

    data() {
        return {
            exception: false,
            search: this.$route.query.search,
            endpoint: this.$route.params.endpoint,
        }
    },

    async mounted() {
        const params = { search: this.search, rd: false }

        try {
            const { data: paginator } = await this.$axios.get(this.endpoint, { params })

            if (paginator.data.length === 1) {
                this.$router.replace({ name: `${this.endpoint}.show`, params: { id: paginator.data[0].id } })
            } else {
                this.$router.replace({ name: this.endpoint, query: params })
            }
        } catch {
            this.exception = true
        }
    },
}
</script>
