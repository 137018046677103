<script setup>
import { ref, watch } from 'vue'
import { toDataURL } from 'qrcode'

const src = ref('')
const props = defineProps({
    value: {
        type: String,
        required: true
    },
    width: {
        type: Number,
        default: 120
    }
})

const options = {
    margin: 2,
    width: props.width,
    errorCorrectionLevel: 'L',
    color: { light: "#f8f6f2" }
}

watch(() => props.value, async (value) => {
    src.value = await toDataURL(value, options)
}, { immediate: true })
</script>

<template>
    <img :src="src" class="qr-code">
</template>