import { ref } from 'vue'

let timeoutId = null
const toast = ref(null)

const closeToast = () => {
    clearTimeout(timeoutId)
    toast.value = null
    timeoutId = null
}

export { toast, closeToast }

export default (text, type = 'success', duration = 3000) => {
    clearTimeout(timeoutId)

    toast.value = { text, type }
    timeoutId = setTimeout(closeToast, duration)
}