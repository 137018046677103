const EVENT_TYPE_MAPPING = {
    title: { author: 'user', merge: false },
    closed: { author: 'user', merge: false },
    reopened: { author: 'user', merge: false },
    comment: { author: 'author', merge: false },
    description: { author: 'user', merge: false },
    assignment: { author: 'assigned_by', merge: true, extract: 'name', from: 'assignee' },
    unassignment: { author: 'unassigned_by', merge: true, extract: 'name', from: 'unassigned_user' },
    association: { author: 'attached_by', merge: true, extract: 'scan_code', from: 'association_object' },
    disassociation: { author: 'detached_by', merge: true, extract: 'scan_code', from: 'association_object' },
}

const mapTimelineEvents = timeline =>
    timeline.map((item, id) => {
        const event = EVENT_TYPE_MAPPING[item.type]
        if (!event) return

        const { type, timestamp, data } = item
        const { author, merge, from, extract } = event
        const oldData = data.old || null
        const newData = from ? data[from]?.[extract] : data.new || null
        const comment = type === 'comment' ? data : null

        return { id, type, timestamp, merge, author: data[author], oldData, newData, comment }
    }).filter(Boolean)

const mergeTimelineEvents = timeline => {
    const getIdentifier = ({ type, author, timestamp }) => `${type}${author.id}${timestamp.substring(0, 13)}`

    const canItemsBeMerged = (item, nextItem) => nextItem && getIdentifier(item) === getIdentifier(nextItem)

    const formatArrayToCommaSeparatedString = array =>
        new Intl.ListFormat('en', { style: 'short' }).format(array.map(i => `<strong>${i}</strong>`))

    const mergedTimeline = []

    for (let i = 0; i < timeline.length; i++) {
        const item = timeline[i]
        if (!item.merge) {
            mergedTimeline.push(item)
            continue
        }

        const itemsToMerge = [item]
        while (canItemsBeMerged(item, timeline[i + 1])) {
            itemsToMerge.push(timeline[++i])
        }

        item.newData = formatArrayToCommaSeparatedString(itemsToMerge.map(i => i.newData))
        mergedTimeline.push(item)
    }

    return mergedTimeline
}

export const transformTimeline = timeline => mergeTimelineEvents(mapTimelineEvents(timeline))