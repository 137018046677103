<template>
    <Modal v-if="open" :title="$t('auth.welcome')" @close="open = false">
        <p>{{ $t('auth.activate_your_account') }}</p>

        <template #footer>
            <button class="btn" @click="resendActivationEmail()">{{ $t('auth.resend_activation_email') }}</button>
        </template>
    </Modal>
</template>

<script>
export default {
    data: () => ({ open: true, activationEmailSent: false }),

    methods: {
        resendActivationEmail() {
            if (this.activationEmailSent) return

            this.activationEmailSent = true

            this.$axios.post('resend_activation').then(() => {
                this.$toast(this.$root.$t('auth.sent_activation_email'))
            })
        },
    },
}
</script>
