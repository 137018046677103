<template>
    <Dropdown class="dropdown-right">
        <template #toggle>
            <span>{{ $t(`files.filter_${modelValue}`) }}</span>
            <span class="text-bold ml-2" v-if="modelValue in counters">{{ counters[modelValue] }}</span>
            <i class="fas fa-fw fa-filter ml-2"></i>
        </template>
        <div class="menu-item">
            <a href="#" :class="{ active: modelValue === 'all' }" @click.prevent="updateFilter('all')">
                <span>{{ $t('files.filter_all') }}</span>
            </a>
        </div>
        <div v-for="(count, key) in counters" v-show="count" class="menu-item">
            <a href="#" :class="{ active: modelValue === key }" @click.prevent="updateFilter(key)">
                <span class="text-clip">{{ $t(`files.filter_${key}`) }}</span>
                <span class="text-bold ml-2">{{ count }}</span>
            </a>
        </div>
        <div class="menu-item" v-if="hasFields">
            <a href="#" :class="{ active: modelValue === 'fields' }" @click.prevent="updateFilter('fields')">
                <span>{{ $t('files.filter_fields') }}</span>
            </a>
        </div>
        <div class="exclude-from-share">
            <div class="divider"></div>
            <div class="menu-item">
                <a href="#" :class="{ loading }" @click.prevent="toggleShares(true)">
                    <span>{{ $t('files.toggle_shared_enable') }}</span>
                </a>
            </div>
            <div class="menu-item">
                <a href="#" :class="{ loading }" @click.prevent="toggleShares(false)">
                    <span>{{ $t('files.toggle_shared_disable') }}</span>
                </a>
            </div>
        </div>
    </Dropdown>
</template>

<script>
export default {
    props: {
        file: Object,
        modelValue: String,
    },

    emits: ['update:modelValue', 'refresh'],

    data() {
        return {
            loading: false,
            hasFields: this.file.fields?.length,
            counters: { snap: 0, snap_with_damage: 0, attachment: 0, document: 0, video: 0, form: 0, seal: 0 },
        }
    },

    mounted() {
        for (const item of this.file.timeline) {
            if (item.has_damage) {
                this.counters.snap_with_damage++
            }

            if (item.upload_type in this.counters) {
                this.counters[item.upload_type]++
            }
        }
    },

    methods: {
        updateFilter(filter) {
            this.$emit('update:modelValue', filter)
            this.$router.replace({ ...this.$route, query: { filter } })
        },

        async toggleShares(include_in_share) {
            if (this.loading) return

            const items = this.getItemsToShareToggle(this.modelValue)

            if (!items.length) return

            this.loading = true

            await this.$axios.patch(`files/${this.file.id}/include_items_in_share`, { include_in_share, items })

            this.$emit('refresh')
        },

        getItemsToShareToggle(filter) {
            switch (filter) {
                case 'all':
                    return this.file.timeline
                case 'snap_with_damage':
                    return this.file.timeline.filter(u => u.upload_type === 'snap' && u.has_damage)
                default:
                    return this.file.timeline.filter(u => u.upload_type === filter)
            }
        }
    }
}
</script>