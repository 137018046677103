import router from '~/router'

// Returns index name of the current route
export const getRouteIndexName = () => {
    const routeName = router.currentRoute.value.name

    if (!routeName) return

    const [split1, split2] = routeName.split('.')

    // Example: settings.users.show -> settings.users
    if (split1 === 'settings') return split1 + '.' + split2

    // Example: files.show -> files
    return split1
}