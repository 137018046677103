import i18n from '~/plugins/i18n.js'
import toast from '~/plugins/toast.js'

export const generateUniqueId = () => {
    return Math.random().toString(36).substring(2, 7)
}

export const downloadFile = url => {
    if (!url) return toast(i18n.global.t('main.oops'), 'error')

    toast(i18n.global.t('main.downloading'))

    const link = document.createElement('a')
    link.href = url
    link.download = url

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
}