// Middleware
import isLoggedIn from './middleware/isLoggedIn.js'
import isLoggedOut from './middleware/isLoggedOut.js'
import isKioskUser from './middleware/isKioskUser.js'

// Pages
import debug_index from '../pages/debug/index.vue'
import page_not_found from '../pages/page_not_found.vue'

// Auth Pages
import pages_auth_login from '../pages/auth/login.vue'
import pages_auth_register from '../pages/auth/register.vue'
import pages_auth_password_email from '../pages/auth/password/email.vue'
import pages_auth_password_reset from '../pages/auth/password/reset.vue'
import pages_auth_activate from '../pages/auth/activate.vue'
import pages_auth_invited from '../pages/auth/invited.vue'

// Share Pages
import share_shared_file from '../pages/share/shared_file.vue'
import share_print from '../pages/share/print.vue'

// Kiosk Pages
import kiosk_index from '../pages/kiosk/index.vue'
import kiosk_show from '../pages/kiosk/show.vue'

// Platform Pages
import platform_trial from '../pages/platform/trial.vue'
import platform_redirect_by_payload from '../pages/platform/redirect_by_payload.vue'
import platform_redirect from '../pages/platform/redirect.vue'
import platform_dashboard from '../pages/platform/dashboard.vue'

// Platform Files Pages
import platform_files_index from '../pages/platform/files/index.vue'
import platform_files_create from '../pages/platform/files/create.vue'
import platform_files_single_add_field from '../pages/platform/files/single/add_field.vue'
import platform_files_single_create_report from '../pages/platform/files/single/create_report.vue'
import platform_files_single_send_images from '../pages/platform/files/single/send_images.vue'
import platform_files_single_download_zip from '../pages/platform/files/single/download_zip.vue'
import platform_files_single_create_issue from '../pages/platform/files/single/create_issue.vue'
import platform_files_single_show from '../pages/platform/files/single/show.vue'
import platform_files_single_history from '../pages/platform/files/single/history.vue'
import platform_files_single_share_create from '../pages/platform/files/single/share/create.vue'
import platform_files_single_share_active from '../pages/platform/files/single/share/active.vue'
import platform_files_single_upload from '../pages/platform/files/single/upload.vue'
import platform_files_single_submit_form from '../pages/platform/files/single/submit_form.vue'
import platform_files_single_submit_form_create from '../pages/platform/files/single/submit_form.create.vue'
import platform_forms_submit_show from '../pages/platform/forms/submit/show.vue'
import platform_files_single_print from '../pages/platform/files/single/print.vue'

// Platform Sharing Pages
import platform_sharing_index from '../pages/platform/sharing/index.vue'
import platform_sharing_out from '../pages/platform/sharing/out.vue'
import platform_sharing_show from '../pages/platform/sharing/show.vue'

// Platform Workflows Pages
import platform_workflows_index from '../pages/platform/workflows/index.vue'
import platform_workflows_order from '../pages/platform/workflows/order.vue'
import platform_workflows_show from '../pages/platform/workflows/show.vue'

// Platform Forms Pages
import platform_forms_index from '../pages/platform/forms/index.vue'
import platform_forms_order from '../pages/platform/forms/order.vue'
import platform_forms_show from '../pages/platform/forms/show.vue'

// Platform Reports Pages
import platform_reports_index from '../pages/platform/reports/index.vue'

// Platform Kiosk Submits Pages
import platform_kiosk_submits_index from '../pages/platform/kiosk_submits/index.vue'
import platform_kiosk_submits_show from '../pages/platform/kiosk_submits/show.vue'

// Platform Issues Pages
import platform_issues_index from '../pages/platform/issues/index.vue'
import platform_issues_create from '../pages/platform/issues/create.vue'
import platform_issues_show from '../pages/platform/issues/show.vue'

// Platform Settings Pages
import platform_settings_index from '../pages/platform/settings/index.vue'
import platform_settings_profile_index from '../pages/platform/settings/profile/index.vue'
import platform_settings_profile_workspace from '../pages/platform/settings/profile/workspace.vue'
import platform_settings_profile_notifications from '../pages/platform/settings/profile/notifications.vue'
import platform_settings_teams_index from '../pages/platform/settings/teams/index.vue'
import platform_settings_teams_create from '../pages/platform/settings/teams/create.vue'
import platform_settings_teams_show from '../pages/platform/settings/teams/show.vue'
import platform_settings_devices_index from '../pages/platform/settings/devices/index.vue'
import platform_settings_devices_create from '../pages/platform/settings/devices/create.vue'
import platform_settings_devices_show from '../pages/platform/settings/devices/show.vue'
import platform_settings_devices_activate from '../pages/platform/settings/devices/activate.vue'
import platform_settings_users_index from '../pages/platform/settings/users/index.vue'
import platform_settings_users_create from '../pages/platform/settings/users/create.vue'
import platform_settings_users_show from '../pages/platform/settings/users/show.vue'
import platform_settings_fields_index from '../pages/platform/settings/fields/index.vue'
import platform_settings_fields_show from '../pages/platform/settings/fields/show.vue'
import platform_settings_company_index from '../pages/platform/settings/company/index.vue'
import platform_settings_company_permissions from '../pages/platform/settings/company/permissions.vue'
import platform_settings_company_api_tokens_index from '../pages/platform/settings/company/api_tokens/index.vue'
import platform_settings_company_api_tokens_show from '../pages/platform/settings/company/api_tokens/show.vue'
import platform_settings_company_webhooks_index from '../pages/platform/settings/company/webhooks/index.vue'
import platform_settings_company_webhooks_show from '../pages/platform/settings/company/webhooks/show.vue'
import platform_settings_company_contact_lists_index from '../pages/platform/settings/company/contact_lists/index.vue'
import platform_settings_company_contact_lists_show from '../pages/platform/settings/company/contact_lists/show.vue'

// Platform Admin Pages
import platform_admin_companies_index from '../pages/platform/admin/companies/index.vue'
import platform_admin_companies_show from '../pages/platform/admin/companies/show.vue'

export default [
    // Public/anonymous share
    {
        name: 'share',
        path: '/share/:token',
        meta: { layout: 'SharedFileLayout' },
        component: share_shared_file,
    },
    {
        name: 'share_print',
        path: '/share/:token/print',
        meta: { layout: 'PrintFileLayout' },
        component: share_print,
    },

    // Non-logged in routes
    {
        name: 'login',
        path: '/login',
        beforeEnter: isLoggedOut,
        meta: { layout: 'AuthLayout' },
        component: pages_auth_login,
    },
    {
        name: 'register',
        path: '/register',
        beforeEnter: isLoggedOut,
        meta: { layout: 'AuthLayout' },
        component: pages_auth_register,
    },
    {
        name: 'password.request',
        path: '/password/reset',
        meta: { layout: 'AuthLayout' },
        component: pages_auth_password_email,
    },
    {
        name: 'password.reset',
        path: '/password/reset/:token',
        meta: { layout: 'AuthLayout' },
        component: pages_auth_password_reset,
    },
    {
        name: 'invited',
        path: '/invited/:token',
        meta: { layout: 'AuthLayout' },
        component: pages_auth_invited,
    },
    {
        name: 'redirect-by-payload',
        path: '/redirect-by-payload/:payload',
        component: platform_redirect_by_payload,
    },

    // Kiosk (Logged-in)
    {
        name: 'kiosk',
        path: '/kiosk',
        beforeEnter: isKioskUser,
        meta: { layout: 'KioskLayout' },
        component: kiosk_index,
        children: [
            {
                path: ':id',
                name: 'kiosks.show',
                component: kiosk_show,
            },
        ],
    },

    // Platform (Logged-in)
    {
        name: 'dashboard',
        path: '/',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_dashboard,
    },
    {
        name: 'trial',
        path: '/trial',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_trial,
    },
    {
        name: 'activate',
        path: '/activate/:token',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: pages_auth_activate,
    },
    {
        name: 'redirect',
        path: '/redirect/:endpoint(files)',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_redirect,
    },

    {
        name: 'files',
        path: '/files',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_files_index,
        children: [
            {
                path: 'create',
                name: 'files.create',
                component: platform_files_create,
            },
            {
                path: 'add_field',
                name: 'files.multiple.add_field',
                component: platform_files_single_add_field,
            },
            {
                path: 'create_report',
                name: 'files.multiple.create_report',
                component: platform_files_single_create_report,
            },
            {
                path: 'send_images',
                name: 'files.multiple.send_images',
                component: platform_files_single_send_images,
            },
            {
                path: 'download_zip',
                name: 'files.multiple.download_zip',
                component: platform_files_single_download_zip,
            },
        ],
    },

    {
        path: '/files/:id',
        name: 'files.show',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_files_single_show,
        children: [
            {
                path: 'history',
                name: 'files.history',
                component: platform_files_single_history,
            },
            {
                path: 'share',
                name: 'files.share.create',
                component: platform_files_single_share_create,
            },
            {
                path: 'share/active',
                name: 'files.share.active',
                component: platform_files_single_share_active,
            },
            {
                path: 'upload',
                name: 'files.upload',
                component: platform_files_single_upload,
            },
            {
                path: 'submit_form',
                name: 'files.submit_form',
                component: platform_files_single_submit_form,
            },
            {
                path: 'submit_form/:form_id',
                name: 'files.submit_form.create',
                component: platform_files_single_submit_form_create,
            },
            {
                path: 'submit/:submit_id',
                name: 'files.form_submit.edit',
                component: platform_forms_submit_show,
            },
            {
                path: 'add_field',
                name: 'files.add_field',
                component: platform_files_single_add_field,
            },
            {
                path: 'create_report',
                name: 'files.create_report',
                component: platform_files_single_create_report,
            },
            {
                path: 'send_images',
                name: 'files.send_images',
                component: platform_files_single_send_images,
            },
            {
                path: 'download_zip',
                name: 'files.download_zip',
                component: platform_files_single_download_zip,
            },
            {
                path: 'create_issue',
                name: 'files.create_issue',
                component: platform_files_single_create_issue,
            },
        ],
    },

    {
        name: 'files.print',
        path: '/files/:id/print',
        meta: { layout: 'PrintFileLayout' },
        component: platform_files_single_print,
    },

    {
        name: 'sharing',
        path: '/sharing',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_sharing_index,
    },
    {
        name: 'sharing.out',
        path: '/sharing/out',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_sharing_out,
    },
    {
        name: 'sharing.show',
        path: '/sharing/:id',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_sharing_show,
    },

    {
        name: 'workflows',
        path: '/workflows',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_workflows_index,
        children: [
            {
                path: 'order',
                name: 'workflows.order',
                component: platform_workflows_order,
            },
            {
                path: 'create',
                name: 'workflows.create',
                component: () => import('../pages/platform/workflows/create.vue'),
            },
            {
                path: 'create/new',
                name: 'workflows.new',
                component: () => import('../components/builders/WorkflowBuilder.vue'),
            },
            {
                path: ':id/edit',
                name: 'workflows.edit',
                component: () => import('../components/builders/WorkflowBuilder.vue'),
            },
        ],
    },
    {
        name: 'workflows.show',
        path: '/workflows/:id',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_workflows_show,
    },

    {
        name: 'forms',
        path: '/forms',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_forms_index,
        children: [
            {
                path: 'order',
                name: 'forms.order',
                component: platform_forms_order,
            },
            {
                path: 'create',
                name: 'forms.create',
                component: () => import('../components/builders/FormBuilder.vue'),
            },
            {
                path: ':form_id/edit',
                name: 'forms.edit',
                component: () => import('../components/builders/FormBuilder.vue'),
            },
        ],
    },
    {
        name: 'forms.show',
        path: '/forms/:id',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_forms_show,
        children: [
            {
                name: 'form_submit.show',
                path: 'submit/:submit_id',
                component: platform_forms_submit_show,
            },
        ],
    },

    {
        name: 'reports',
        path: '/reports',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_reports_index,
    },

    {
        name: 'kiosk_submits',
        path: '/kiosk_submits',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_kiosk_submits_index,
        children: [
            {
                path: ':id',
                name: 'kiosk_submits.show',
                component: platform_kiosk_submits_show,
            },
        ],
    },

    // Issue Management
    {
        name: 'issues',
        path: '/issues',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_issues_index,
        children: [
            {
                path: 'create',
                name: 'issues.create',
                component: platform_issues_create,
            },
        ]
    },
    {
        path: '/issues/:issue_number',
        name: 'issues.show',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_issues_show,
    },

    // Settings
    {
        path: '/settings',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_settings_index,
        children: [
            {
                path: '',
                name: 'settings',
                component: platform_settings_profile_index,
            },
            {
                path: 'workspace',
                name: 'settings.workspace',
                component: platform_settings_profile_workspace,
            },
            {
                path: 'notifications',
                name: 'settings.notifications',
                component: platform_settings_profile_notifications,
            },

            {
                path: 'teams',
                alias: '/teams',
                name: 'settings.teams',
                component: platform_settings_teams_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.teams.create',
                        component: platform_settings_teams_create,
                    },
                    {
                        path: ':id',
                        name: 'settings.teams.show',
                        component: platform_settings_teams_show,
                    },
                ],
            },
            {
                path: 'devices',
                alias: '/devices',
                name: 'settings.devices',
                component: platform_settings_devices_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.devices.create',
                        component: platform_settings_devices_create,
                    },
                    {
                        path: ':id',
                        name: 'settings.devices.show',
                        component: platform_settings_devices_show,
                        children: [
                            {
                                path: 'activate',
                                name: 'settings.devices.activate',
                                component: platform_settings_devices_activate,
                            },
                        ],
                    },
                ],
            },
            {
                path: 'users',
                alias: '/users',
                name: 'settings.users',
                component: platform_settings_users_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.users.create',
                        component: platform_settings_users_create,
                    },
                    {
                        path: ':id',
                        name: 'settings.users.show',
                        component: platform_settings_users_show,
                    },
                ],
            },

            {
                path: 'fields',
                name: 'settings.fields',
                component: platform_settings_fields_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.fields.create',
                        component: platform_settings_fields_show,
                    },
                    {
                        path: ':field_id/edit',
                        name: 'settings.fields.edit',
                        component: platform_settings_fields_show,
                    },
                ],
            },

            {
                path: 'company',
                name: 'settings.company',
                component: platform_settings_company_index,
            },
            {
                path: 'permissions',
                name: 'settings.permissions',
                component: platform_settings_company_permissions,
            },

            {
                path: 'api_tokens',
                name: 'settings.api_tokens',
                component: platform_settings_company_api_tokens_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.api_tokens.create',
                        component: platform_settings_company_api_tokens_show,
                    },
                ],
            },

            {
                path: 'webhooks',
                name: 'settings.webhooks',
                component: platform_settings_company_webhooks_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.webhooks.create',
                        component: platform_settings_company_webhooks_show,
                    },
                    {
                        path: ':id',
                        name: 'settings.webhooks.show',
                        component: platform_settings_company_webhooks_show,
                    },
                ],
            },

            {
                path: 'contact_lists',
                name: 'settings.contact_lists',
                component: platform_settings_company_contact_lists_index,
                children: [
                    {
                        path: 'create',
                        name: 'settings.contact_lists.create',
                        component: platform_settings_company_contact_lists_show,
                    },
                    {
                        path: ':id',
                        name: 'settings.contact_lists.show',
                        component: platform_settings_company_contact_lists_show,
                    },
                ],
            },
        ],
    },

    // Billing (abowire)
    {
        path: '/billing',
        alias: '/subscription',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: () => import('../pages/platform/billing/index.vue'),
        children: [
            {
                path: '',
                name: 'billing',
                component: () => import('../pages/platform/billing/subscription.vue'),
            },
            {
                path: 'company_details',
                name: 'billing.company_details',
                component: () => import('../pages/platform/billing/company_details.vue'),
            },
            {
                path: 'payment_method',
                name: 'billing.payment_method',
                component: () => import('../pages/platform/billing/payment_method.vue'),
            },
            {
                path: 'invoices',
                name: 'billing.invoices',
                component: () => import('../pages/platform/billing/invoices.vue'),
            },
        ],
    },

    // Company/tenant overview & statistics (for internal use)
    {
        name: 'companies',
        path: '/admin/companies',
        beforeEnter: isLoggedIn,
        meta: { layout: 'PlatformLayout' },
        component: platform_admin_companies_index,
        children: [
            {
                path: ':id',
                name: 'companies.show',
                component: platform_admin_companies_show,
            },
            {
                path: ':id/statistics',
                name: 'companies.stats',
                component: () => import('../pages/platform/admin/companies/statistics.vue'),
            },
        ],
    },

    // Debug info
    {
        name: 'debug',
        path: '/debug',
        component: debug_index,
    },

    {
        path: '/:catchAll(.*)',
        component: page_not_found,
    },
]
