<template>
    <figure class="avatar text-uppercase" :data-initial="computedInitials" :style="{ backgroundColor }">
        <div v-if="online" class="avatar-presence online"></div>
    </figure>
</template>

<script>
export default {
    props: {
        online: { type: Boolean, default: false },
        initials: { type: [String, Number], default: '' },
    },

    computed: {
        computedInitials() {
            const [word1, word2] = String(this.initials).split(' ')

            return this.getInitial(word1) + this.getInitial(word2)
        },

        backgroundColor() {
            const firstCharCode = this.computedInitials.toLowerCase().charCodeAt(0) - 97

            return this.colorArray[firstCharCode]
        }
    },

    methods: {
        getInitial(string) {
            const firstLetter = (string || '').charAt(0)
            const isAlphanumeric = /[a-zA-Z0-9]/.test(firstLetter)

            return isAlphanumeric ? firstLetter : ''
        }
    },

    data() {
        return {
            colorArray: [
                '#009B77', // A
                '#0072B5', // B
                '#FF6F61', // C
                '#88B04B', // D
                '#955251', // E
                '#B565A7', // F
                '#006B54', // G
                '#DD4124', // H
                '#5B5EA6', // I
                '#45B8AC', // J
                '#EFC050', // K
                '#32CD32', // L
                '#9B2335', // M
                '#BC243C', // N
                '#FF5001', // O
                '#B55A30', // P
                '#34568B', // Q
                '#6A2E2A', // R
                '#00A170', // S
                '#926AA6', // T
                '#D2386C', // U
                '#363945', // V
                '#9A8B4F', // W
                '#CD212A', // X
                '#34568B', // Y
                '#4B5335'  // Z
            ]
        }
    }
}
</script>
