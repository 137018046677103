<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({ options: Object, onConfirm: Function, onCancel: Function })
const input = ref(props.options.input)
const inputRef = ref(null)
const buttonRef = ref(null)

const hasInput = typeof props.options.input === 'string'

onMounted(() => {
    if (hasInput) inputRef.value.focus()
})
</script>

<template>
    <Modal :title="options.title || $t('main.are_you_sure')" @close="onCancel">
        <h5>{{ options.text }}</h5>

        <div v-if="options.errorMessage" class="mt-4 text-error">{{ options.errorMessage }}</div>

        <input v-if="hasInput" ref="inputRef" v-model="input" class="form-input my-4"
            @keyup.enter="input && buttonRef.click()" />

        <template #footer>
            <SaveButton @click="onConfirm(input)" ref="buttonRef" :disabled="hasInput && !input" autoFocus>
                {{ options.buttonText || $t('main.confirm') }}
            </SaveButton>
            <button class="btn ml-2" @click="onCancel">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>