<template>
    <div :id="`upload-${item.id}`" class="timeline-item">
        <div class="timeline-left">
            <a :href="`#upload-${item.id}`" class="timeline-icon">
                <i class="fas fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t(`timeline.attachment`, { by: item.device_nick }) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-3 col-xl-5 col-md-12">
                        <div class="flex-start label" style="height: 100px">
                            <i class="far mx-2 fa-fw fa-2xl" :class="icon"></i>
                            <div class="text-ellipsis">
                                <a :href="item.image_url" target="_blank">{{ item.image_name }}</a>
                                <div class="text-gray text-small">
                                    <span>{{ size }}</span> ·
                                    <DateTime :date="item.created_at"></DateTime>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <table class="table">
                            <tbody>
                                <tr v-if="showReference">
                                    <td>{{ $t('files.reference') }}</td>
                                    <td>
                                        <router-link :to="{ name: 'files.show', params: { id: item.file_id } }">
                                            {{ item.scan_code }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr class="uploaded-by" v-if="!hideUploader">
                                    <td>{{ $t('timeline.uploaded_by') }}</td>
                                    <td>
                                        <router-link v-if="item.device_id"
                                            :to="{ name: 'settings.devices.show', params: { id: item.device_id } }">
                                            {{ item.device_nick }}
                                        </router-link>
                                        <div v-else>{{ item.device_nick }}</div>
                                    </td>
                                </tr>
                                <tr class="comment" v-if="item.comment">
                                    <td>{{ $t('timeline.comment') }}</td>
                                    <td>{{ item.comment }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-snap btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <button @click="$emit('edit', item)" class="edit-snap btn mr-2">
                                <i class="fas fa-pencil"></i>
                            </button>

                            <a :href="item.image_url" target="_blank" class="btn mr-2">
                                <i class="far fa-circle-down"></i>
                            </a>

                            <button @click="$emit('share', item)" class="edit-snap btn">
                                <i class="fas fa-share-alt" :class="{ 'text-gray': !item.include_in_share }"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        showReference: Boolean,
        hideUploader: Boolean,
    },

    computed: {
        icon() {
            const mime = this.item.image_mime

            if (mime.includes('pdf')) {
                return 'fa-file-pdf'
            }

            if (mime.includes('excel') || mime.includes('spreadsheet')) {
                return 'fa-file-excel'
            }

            if (mime.includes('word')) {
                return 'fa-file-word'
            }

            return 'fa-file'
        },

        size() {
            const bytes = this.item.image_size

            const i = Math.floor(Math.log(bytes) / Math.log(1024))
            return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ['b', 'kb', 'mb'][i]
        },
    },
}
</script>