import store from '~/store'

export default (to, from, next) => {
    if (!store.state.loggedIn) {
        store.dispatch('endSession', to.fullPath)

        return next({ name: 'login' })
    }

    // Redirect non kiosk users
    if (!store.state.jwt.kiosk) {
        return next({ name: 'dashboard' })
    }

    return next()
}
