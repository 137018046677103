<script setup>
import { ref } from 'vue'

const props = defineProps({ options: Object, onConfirm: Function, onCancel: Function })
const input = ref(null)

// Primary style is used for closing files only,
// for the rest of the actions it's danger styling
const isPrimary = props.options.style === 'primary'

// Validate count is used to confirm the number of items to be deleted
// But it's not required when only one file/report is being deleted/closed
const shouldValidateCount = props.options.validateCount > 1
</script>

<template>
    <Modal :title="$t('main.are_you_sure')" @close="onCancel">
        <h5 v-if="!isPrimary">{{ $t('main.you_wont_be_able_to_revert_this') }}</h5>

        <input v-if="shouldValidateCount" v-model="input" class="form-input my-4"
            :placeholder="options.confirmPlaceholder" />

        <template #footer>
            <SaveButton :class="{ 'btn-error': !isPrimary }"
                :disabled="shouldValidateCount && input != options.validateCount" @click="onConfirm" autoFocus>
                {{ options.buttonText || $t('main.yes_delete_it') }}
            </SaveButton>
            <button class="btn ml-2" @click="onCancel">{{ $t('main.cancel') }}</button>
        </template>
    </Modal>
</template>