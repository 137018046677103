<template>
    <Transition name="fade" appear>
        <div class="modal-custom" ref="bg">
            <div class="modal-wrapper" v-click-outside="close">
                <div class="modal-header">
                    <div class="flex-start">
                        <h4 class="text-bold mb-0 mr-2">{{ title }}</h4>
                        <slot name="subtitle"></slot>

                        <button v-if="onClose" class="btn btn-clear" @click="close()"></button>
                    </div>
                </div>

                <div class="modal-content">
                    <slot></slot>
                </div>

                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    props: {
        title: String,
        onClose: [Function, Array]
    },

    mounted() {
        document.body.classList.add('no-scroll')
    },

    unmounted() {
        // dont show another scrollbar when closing a child modal
        const hasAnotherModalOpen = document.querySelector('.modal-custom')

        if (hasAnotherModalOpen) return

        document.body.classList.remove('no-scroll')
    },

    methods: {
        close() {
            if (!this.onClose) return

            this.$emit('close')
        },
    },
}
</script>
