<script setup>
import { useAttrs, defineAsyncComponent } from 'vue'

const attrs = useAttrs()
const model = defineModel()

const MapsView = defineAsyncComponent(() => import('./MapsView.vue'))
</script>

<template>
    <Suspense>
        <MapsView v-model="model" v-bind="attrs" />
        <template #fallback>
            <div class="box leaflet-container loading loading-lg" style="height: 400px"></div>
        </template>
    </Suspense>
</template>