<template>
    <button class="btn btn-primary" :class="{ loading }" @click="click">
        <slot>{{ $t('main.save') }}</slot>
    </button>
</template>

<script>
export default {
    props: {
        onClick: Function,
        autoFocus: Boolean
    },

    data: () => ({ loading: false }),

    mounted() {
        if (this.autoFocus) {
            this.$el.focus()
        }
    },

    methods: {
        async click() {
            if (this.loading) return

            this.loading = true

            try {
                await this.onClick()
            } catch (e) {
                throw e
            } finally {
                this.loading = false
            }
        }
    }
}
</script>