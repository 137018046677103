<template>
    <div>
        <ContextBar :title="$t('main.kiosk_submits')"></ContextBar>

        <DataTable endpoint="kiosk_submits" :columns="columns" :key="refresh" poll>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(kiosk_id)="{ item }">{{ item.kiosk.title }}</template>
            <template #column(reference)="{ value }">{{ value }}</template>
            <template #column(answers)="{ value }">
                <div class v-for="answer in value" :key="answer.id">
                    <i>{{ answer.step.question }}</i>
                    {{ answer.answer }}
                </div>
            </template>
            <template #column(accept)="{ item }">
                <router-link :to="{ name: 'kiosk_submits.show', params: { id: item.id } }" class="btn btn-primary">
                    <i class="fas fa-edit mr-2"></i>
                    {{ $t('kiosk.editandaccept') }}
                </router-link>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right">
                    <div class="menu-item text-error">
                        <a href="#" @click.prevent="deleteSubmit(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,

            columns: [
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'kiosk_id', th: 'kiosk.kiosk', sortable: true },
                { name: 'reference', th: 'files.reference', sortable: true },
                { name: 'answers', th: 'kiosk.submit' },
                { name: 'accept', th: 'kiosk.editandaccept' },
                { name: 'actions', min: true },
            ],
        }
    },

    methods: {
        deleteSubmit(submit) {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`kiosk_submits/${submit.id}`)
                this.refresh++
            })
        },
    },
}
</script>
