<template>
    <div class="text-center">
        <img height="250" src="/images/snappy/idea2.png" alt />
        <div class="text-gray">{{ limitText || $t('license_limit.limit') }}</div>
        <div class="text-gray">{{ text }}</div>
    </div>
</template>

<script>
export default {
    props: { text: String, limitText: String },
}
</script>
