<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { props } from '~/plugins/dialog.js'

import InfoDialog from './InfoDialog.vue'
import DangerDialog from './DangerDialog.vue'
import ConfirmDialog from './ConfirmDialog.vue'

const route = useRoute()
const { open, options, handleConfirm, handleCancel } = props

const components = {
    'InfoDialog': InfoDialog,
    'DangerDialog': DangerDialog,
    'ConfirmDialog': ConfirmDialog,
}

watch(() => route.name, handleCancel)
</script>

<template>
    <component v-if="open" :is="components[options.component]" :key="options.title" :options="options"
        @confirm="handleConfirm" @cancel="handleCancel" class="modal-dialog" />
</template>