<template>
    <WatchForUnsavedChanges ref="watch" v-model="locationFilters" v-slot="{ hasChanges, cancel }">
        <ContextBar :title="$t('profile.profile')"></ContextBar>

        <div class="profile-wrapper flex-space my-2">
            <Avatar :initials="$store.state.user.name" class="mr-2"></Avatar>
            <div class="ml-2" style="flex: 1">
                <div class="text-bold">{{ $store.state.user.name }}</div>
                <div>{{ $store.state.user.email }}</div>
            </div>
        </div>

        <div class="my-4">
            <div v-if="editPassword" class="profile-wrapper">
                <PasswordChange @close="editPassword = false" cancelBtn currentPw></PasswordChange>
            </div>
            <a v-else href="#" @click.prevent="editPassword = true">{{ $t('profile.change_password') }}</a>
        </div>

        <h6>{{ $t('main.language') }}</h6>
        <LanguageDropdown save></LanguageDropdown>

        <template v-if="!isFreeAccount">
            <h6>{{ $t('profile.location_filters') }}</h6>

            <label class="form-switch text-bold">
                <input :checked="allLocationsSelected" @change="toggleSelectAllLocations()" type="checkbox" />
                <i class="form-icon"></i>
                <span>{{ $t('fields.all_locations') }}</span>
            </label>

            <label v-for="location in locationFilters" :key="location.id" class="form-switch">
                <input v-model="location.selected" type="checkbox" />
                <i class="form-icon"></i>
                <span>{{ location.location }}</span>
            </label>

            <div class="context-bar">
                <SaveButton @click="save()" :disabled="!hasChanges" />
                <button class="btn" @click="cancel" :disabled="!hasChanges">{{ $t('main.cancel') }}</button>
            </div>
        </template>
    </WatchForUnsavedChanges>
</template>

<script>
import { mapGetters } from 'vuex'
import Avatar from '~/components/Avatar.vue'
import PasswordChange from '~/components/PasswordChange.vue'

export default {
    components: { Avatar, PasswordChange },

    data: () => ({ editPassword: false, locationFilters: [] }),

    async mounted() {
        const { data: settings } = await this.$axios.get('settings')

        this.locationFilters = settings.location_filters.sort((a, b) => a.location.localeCompare(b.location))
    },

    computed: {
        ...mapGetters(['isFreeAccount']),

        allLocationsSelected() {
            return this.locationFilters.length === this.locationFilters.filter(location => location.selected).length
        },
    },

    methods: {
        toggleSelectAllLocations() {
            const toggle = !this.allLocationsSelected
            this.locationFilters.forEach(location => location.selected = toggle)
        },

        async save() {
            const selected = this.locationFilters.filter(location => location.selected === true)

            await this.$axios.post('location_filters', selected)

            this.$toast(this.$root.$t('profile.settings_updated'))
            this.$refs.watch?.init()
        },
    },
}
</script>
