<template>
    <div class="sidepanel-wrapper">
        <div class="sidepanel-backdrop"></div>
        <div class="sidepanel" v-click-outside="close">
            <div class="sidepanel-header">
                <div class="sidepanel-title">{{ title }}</div>
                <button class="btn btn-clear" @click="$emit('close')"></button>
            </div>

            <div class="sidepanel-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title'],

    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>
