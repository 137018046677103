export const tenantCanCreateDocxExport = tenantId => {
    const allowedTenants = [
        35, 62, 68, 69, 74, 96, 109, 111, 115, 130, 139, 195,
        206, 211, 270, 274, 296, 345, 361, 367, 368, 369, 379,
        419, 420, 535, 552, 571, 627, 632, 676, 681, 707, 734, 766,
        806, 1509, 1643, 1888, 2003, 2459
    ]

    return allowedTenants.includes(tenantId)
}

export const tenantCanUseGalleryPickerWidget = tenantId => {
    const allowedTenants = [1, 243, 291, 348, 664]

    return allowedTenants.includes(tenantId)
}

export const tenantHidePlacesInShare = tenantId => {
    const hideForTenants = [383]

    return hideForTenants.includes(tenantId)
}
