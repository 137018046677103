<template>
    <Modal v-if="show" :title="$t('devices.manage_widgets')" @close="show = false" class="widget-manager">
        <div class="widget-slider-wrapper">
            <div class="flex-space mb-4">
                <h4 class="text-bold">{{ $t('devices.widgets_top') }}</h4>
                <select v-model="topSelect" @change="add(topSelect, swiper_top)" class="form-select w-250">
                    <option :value="null" disabled hidden>{{ $t('devices.choose_widget') }}</option>
                    <option v-for="a in swipers.top" :value="a">{{ $t(`widgets.${a.name}`) }}</option>
                </select>
            </div>

            <div class="widget-slider">
                <Container @drop="onDropTop" orientation="horizontal" lock-axis="x">
                    <Draggable v-for="(item, index) in swiper_top" :key="index">
                        <div class="draggable-item">
                            <div class="widget-icon">
                                <img :src="'/images/widgets/' + mapTypeToIcon(item.name)" class="img-invertable" />
                            </div>
                            <div class="text-large">{{ $t(`widgets.${item.name}`) }}</div>
                            <div class="widget-edit" v-if="widgetHasConfiguration(item.name)">
                                <button class="btn" @click="editConfiguration = item">
                                    <i class="fas fa-cog"></i>
                                </button>
                            </div>
                            <div class="widget-trash">
                                <button class="btn" @click="remove(swiper_top, index)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </Draggable>
                </Container>
            </div>
        </div>

        <div class="widget-slider-wrapper">
            <div class="flex-space mb-4">
                <h4 class="text-bold">{{ $t('devices.widgets_bottom') }}</h4>
                <select v-model="bottomSelect" @change="add(bottomSelect, swiper_bottom)" class="form-select w-250">
                    <option :value="null" disabled hidden>{{ $t('devices.choose_widget') }}</option>
                    <option v-for="a in swipers.bottom" :value="a" :key="a.name">{{ $t(`widgets.${a.name}`) }}</option>
                </select>
            </div>

            <div class="widget-slider">
                <Container @drop="onDropBottom" orientation="horizontal" lock-axis="x">
                    <Draggable v-for="(item, index) in swiper_bottom" :key="index">
                        <div class="draggable-item">
                            <div class="widget-icon">
                                <img :src="'/images/widgets/' + mapTypeToIcon(item.name)" class="img-invertable" />
                            </div>
                            <div class="text-large">{{ $t(`widgets.${item.name}`) }}</div>
                            <div class="widget-edit" v-if="item.name === 'workflows'">
                                <a href="/workflows" target="_blank" class="btn">
                                    <i class="fas fa-cog"></i>
                                </a>
                            </div>
                            <div class="widget-edit" v-else-if="widgetHasConfiguration(item.name)">
                                <button class="btn" @click="editConfiguration = item">
                                    <i class="fas fa-cog fa-fw"></i>
                                </button>
                            </div>
                            <div class="widget-trash">
                                <button class="btn" @click="remove(swiper_bottom, index)">
                                    <i class="fas fa-trash fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </Draggable>
                </Container>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-primary" @click="saveAndClose()">{{ $t('main.save') }}</button>
        </template>

        <WidgetInternalConfiguration v-if="editConfiguration" v-model="editConfiguration"
            @close="editConfiguration = null" @update="saveWidgetConfiguration" />
    </Modal>
</template>

<script>
import { Container, Draggable } from 'vue3-smooth-dnd'
import WidgetInternalConfiguration from './WidgetInternalConfiguration.vue'
import { tenantCanUseGalleryPickerWidget } from '~/guards/featureGuard.js'

export default {
    props: ['widgets'],

    components: { Container, Draggable, WidgetInternalConfiguration },

    data() {
        return {
            show: false,

            swiper_top: [],
            swiper_bottom: [],

            topSelect: null,
            bottomSelect: null,

            editConfiguration: null,

            swipers: {
                top: [
                    { name: 'barcode' },
                    { name: 'container' },
                    { name: 'vcontainer' },
                    { name: 'textScanner' },
                    { name: 'plate_br' },
                    { name: 'plate_eu' },
                    {
                        name: 'timestamp',
                        settings: {
                            timezone: '',
                            timestamp_format: '',
                        },
                    },
                    {
                        name: 'get_file',
                        settings: {
                            exclude_files_by_sql_mask: '',
                            only_get_files_made_by_device: false,
                        },
                    },
                    { name: 'type' },
                    { name: 'vin' },
                    { name: 'number' }
                ],
                bottom: [
                    { name: 'document' },
                    { name: 'forms' },
                    { name: 'serial_snapper' },
                    { name: 'serialBarcode', settings: {} },
                    { name: 'snap' },
                    { name: 'video' },
                    { name: 'workflows' },
                ],
            },
        }
    },

    created() {
        if (tenantCanUseGalleryPickerWidget(this.$store.state.user.tenant_id)) {
            this.swipers.bottom.push({ name: 'galleryPicker' })
        }
    },

    methods: {
        mapTypeToIcon(type) {
            const types = {
                vin: 'vin.svg',
                document: 'document.svg',
                forms: 'forms.svg',
                barcode: 'barcode.svg',
                serialBarcode: 'barcode.svg',
                galleryPicker: 'gallerypicker.svg',
                serial_snapper: 'serialsnap.svg',
                snap: 'snap.svg',
                video: 'video.svg',
                workflows: 'workflow.svg',
                container: 'container.svg',
                get_file: 'recent.svg',
                number: 'numbers.svg',
                plate_br: 'plate_BR.svg',
                plate_eu: 'plate_EU.svg',
                textScanner: 'txt_recognition.svg',
                timestamp: 'timestamp.svg',
                type: 'keyboard.svg',
                vcontainer: 'containervert.svg',
            }

            return types[type]
        },

        showModal() {
            this.show = true
            this.initialize()
        },

        initialize() {
            if (this.widgets && this.widgets.length) {
                this.swiper_top = this.widgets[0] ? this.widgets[0].components : []
                this.swiper_bottom = this.widgets[1] ? this.widgets[1].components : []

                this.swiper_top = this.swiper_top.map(this.applyDefaultsToWidget)
            }
        },

        applyDefaultsToWidget(widget) {
            if (!widget.settings) {
                const defaults = this.swipers.top.find(s => s.name === widget.name) || {}
                widget.settings = defaults.settings || {}
            }

            if (this.$store.getters.can('Use internal features')) {
                widget.settings.transformation = widget.settings.transformation || null
            }

            return widget
        },

        remove(swiper, index) {
            if (swiper.length > 1) {
                swiper.splice(index, 1)
            }
        },

        add(a, swiper) {
            this.topSelect = null
            this.bottomSelect = null

            const widget = { name: a.name, settings: a.settings }
            swiper.push(this.applyDefaultsToWidget(widget))
        },

        save() {
            this.$emit('save', [
                { name: 'swiper_top', components: this.swiper_top },
                { name: 'swiper_bottom', components: this.swiper_bottom },
            ])
        },

        saveAndClose() {
            this.save()
            this.show = false
        },

        saveWidgetConfiguration() {
            this.save()
            this.editConfiguration = null
        },

        widgetHasConfiguration(name) {
            if (!this.$store.getters.can('Use internal features')) return

            return (
                [
                    'barcode',
                    'container',
                    'vcontainer',
                    'notafiscal',
                    'number',
                    'textScanner',
                    'plate_br',
                    'plate_eu',
                    'timestamp',
                    'get_file',
                    'type',
                    'vin',
                    'document',
                    'serialBarcode',
                ].indexOf(name) > -1
            )
        },

        onDropTop(dropResult) {
            this.swiper_top = this.drop(this.swiper_top, dropResult)
        },

        onDropBottom(dropResult) {
            this.swiper_bottom = this.drop(this.swiper_bottom, dropResult)
        },

        drop(arr, dropResult) {
            const { removedIndex, addedIndex, payload } = dropResult

            if (removedIndex === null && addedIndex === null) return arr

            const result = [...arr]
            let itemToAdd = payload

            if (removedIndex !== null) {
                itemToAdd = result.splice(removedIndex, 1)[0]
            }

            if (addedIndex !== null) {
                result.splice(addedIndex, 0, itemToAdd)
            }

            return result
        },
    },
}
</script>
