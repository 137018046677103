<template>
    <div class="box text-center" style="padding: 3rem 0">
        <img class="img-invertable mb-4" src="/images/phone.svg" />
        <h4 class="mb-4">{{ $t('tour.welcome_to_dashboard') }}</h4>
        <div v-if="$store.getters.can('Create files')">
            <router-link :to="{ name: 'files.create' }" class="btn btn-primary mt-4">
                {{ $t('tour.tour_finished_add_my_first_file') }}</router-link>
        </div>
    </div>
</template>
