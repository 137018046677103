<template>
	<table>
		<tbody>
			<tr>
				<td><strong>API</strong></td>
				<td>{{ apiBase }}</td>
			</tr>
			<tr v-for="(item, label) in netlifyContext" :key="label">
				<td><strong>{{ label }}</strong></td>
				<td>{{ item }}</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	computed: {
		apiBase() {
			return this.$axios.defaults.baseURL
		},
		netlifyContext() {
			return window.$netlifyContext
		}
	},
}
</script>