<template>
    <div class="auth">
        <div class="navbar-background">
            <div class="navbar-wrapper">
                <header class="navbar">
                    <section class="navbar-section">
                        <router-link class="navbar-brand" :to="{ name: 'dashboard' }" aria-label="Login"></router-link>
                    </section>
                    <section class="navbar-section">
                        <LanguageDropdown class="dropdown-right"></LanguageDropdown>
                    </section>
                </header>
            </div>
        </div>

        <div class="platform-wrapper">
            <div class="content">
                <div class="container">
                    <div class="columns">
                        <div class="column col-5 col-xl-12">
                            <router-view></router-view>
                        </div>
                        <div class="column col-6 col-xl-12">
                            <div class="image-container hide-xl">
                                <img class="img-responsive" :src="imageForCurrentRoute" loading="lazy" alt="" />
                                <div class="text-right mt-4">{{ $t('copyright', { year }) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        imageForCurrentRoute() {
            return this.images[this.$route.name] || this.images.default
        },
    },

    data() {
        return {
            images: {
                login: '/images/backgrounds/boat-1.webp',
                register: '/images/backgrounds/boat-2.webp',
                default: '/images/backgrounds/boat-3.webp',
            },
            year: new Date().getFullYear(),
        }
    },
}
</script>
