<template>
    <div class="flex-space">
        <router-link :to="{ name: route, params: { id } }" class="table-link">{{ reference }}</router-link>
        <i v-if="closed_at" :title="closed_at" class="fas fa-lock ml-2"></i>
    </div>
</template>

<script>
export default {
    props: {
        id: Number,
        reference: String,
        closed_at: String,
        route: { type: String, default: 'files.show' },
    }
}
</script>
