<template>
    <div class="dark-heading">
        <CompanyLogo v-if="file.company?.logo" :company="file.company" class="mt-4" />

        <Spinner v-if="!file.id"></Spinner>

        <FileShow v-else :file="file" class="shared-file" backRoute="sharing">
            <template #actions>
                <button v-if="allow_zip_download" class="btn" @click="downloadZip()"
                    :class="{ loading: downloading_zip }">
                    {{ $t('files.download_images_as_zip') }}
                </button>
                <button v-if="allow_pdf_download" class="btn" @click="downloadPdf()"
                    :class="{ loading: downloading_pdf }">
                    {{ $t('files.download_images_as_pdf') }}
                </button>
            </template>
        </FileShow>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'
import CompanyLogo from '~/components/CompanyLogo.vue'
import { downloadFile } from '~/helpers'

export default {
    components: { FileShow, CompanyLogo },

    data() {
        return {
            file: {},

            downloading_zip: false,
            allow_zip_download: false,

            downloading_pdf: false,
            allow_pdf_download: false,

            id: this.$route.params.id,
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            const { data: file } = await this.$axios.get(`sharing/${this.id}`)

            file.timeline = file.uploads.concat(file.form_submits)

            const options = file.share.options

            if (options) {
                this.allow_pdf_download = options.allow_pdf_download
                this.allow_zip_download = options.allow_guest_download

                if ('download_pdf' in this.$route.query) {
                    this.downloadPdf()
                    this.$router.replace({ ...this.$router.currentRoute, query: {} })
                }
            }

            this.file = file
        },

        async downloadZip() {
            this.downloading_zip = true

            const { data } = await this.$axios.get(`share/${this.file.share?.token}/zip`)
            downloadFile(data)

            this.downloading_zip = false
        },

        async downloadPdf() {
            this.downloading_pdf = true

            const { data } = await this.$axios.get(`share/${this.file.share?.token}/pdf`)
            downloadFile(data)

            this.downloading_pdf = false
        },
    },
}
</script>
