<template>
    <div class="trial-expiration-indicator flex-space">
        <div>
            <i class="fas fa-circle-info fa-fw fa-lg mr-2"></i>
            <span v-if="trialExpired">{{ $t('auth.your_trial_expired') }}</span>
            <span v-else>{{ $t('auth.your_trial_ends') }} <DateTime :date="expiresAt" ago></DateTime></span>
        </div>
        <router-link v-if="$store.getters.can('Use subscriptions')" class="btn btn-link hide-sm"
            :to="{ name: 'billing' }">
            <span>{{ $t('auth.setup_subscription') }}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['expiresAt'],

    computed: {
        trialExpired() {
            return new Date().toISOString() > this.expiresAt
        },
    },
}
</script>
