<template>
    <div class="flex-start">
        <Avatar :initials="name"></Avatar>
        <div>
            <span class="text-bold">{{ name }}</span>
            <i18n-t :keypath="`issues.timeline_${type}`" scope="global" tag="span">
                <template #old>
                    <span class="text-line-through">{{ oldData }}</span>
                </template>
                <template #new>
                    <span v-html="newData"></span>
                </template>
            </i18n-t>
            <DateTime :date="timestamp" ago></DateTime>
        </div>
    </div>
</template>

<script>
import Avatar from '~/components/Avatar.vue'

export default {
    props: {
        name: String,
        type: String,
        oldData: String,
        newData: String,
        timestamp: String,
    },

    components: { Avatar },
}
</script>