import store from '~/store'

export default (to, from, next) => {
    if (!store.state.loggedIn) {
        store.dispatch('endSession', to.fullPath)

        return next({ name: 'login' })
    }

    // Redirect kiosk users
    if (store.state.jwt.kiosk) {
        return next({ name: 'kiosk' })
    }

    // Redirect free users (they only see sharing)
    if (to.name === 'dashboard' && store.state.jwt.status === 'free') {
        return next({ name: 'sharing' })
    }

    return next()
}
