import AuthenticationContext from 'adal-angular/lib/adal.js'

const config = {
    tenant: 'common',
    prompt: 'select_account',
    clientId: 'b6d85e50-277c-4246-b2ae-61b1fcdbec29',
    redirectUri: location.origin + '/login',
}

export default {
    authenticationContext: null,

    initialize() {
        this.authenticationContext = new AuthenticationContext(config)
    },

    getRequestInfo() {
        return this.authenticationContext.getRequestInfo(location.hash)
    },

    signIn() {
        this.authenticationContext.login()
    },

    signOut() {
        this.authenticationContext.logOut()
    },
}
