const intercomInit = (app_id, delay = 0) => {
    if (!app_id) return

    setTimeout(() => {
        const script = document.createElement('script')
        script.setAttribute('src', `https://widget.intercom.io/widget/${app_id}`)

        script.async = true
        script.onload = () => {
            window.Intercom && window.Intercom('boot', { app_id })
        }

        document.head.appendChild(script)
    }, delay)
}

export default intercomInit

