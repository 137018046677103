<script setup>
import { ref, watch } from 'vue'

const model = defineModel()
const props = defineProps({
    placeholder: String,
    searchIcon: Boolean,
    clearable: Boolean,
    tabindex: Number,
    loading: Boolean,
})

const input = ref(model.value || undefined)
const inputRef = ref(null)

let timeout = null

const clear = () => {
    input.value = ''
    inputRef.value.focus()
}

watch(input, (value) => {
    clearTimeout(timeout)

    if (!value) {
        // When clearing, update original model immediately
        model.value = undefined
        return
    }

    // Update original model by debounce
    timeout = setTimeout(() => { model.value = value }, 250)
})

watch(model, (value) => {
    input.value = value
})

defineExpose({ clear })
</script>

<template>
    <div :class="{ 'has-icon-left': props.searchIcon, 'has-icon-right': props.clearable }">
        <input v-model="input" ref="inputRef" class="form-input" :placeholder="props.placeholder || $t('main.search')"
            :tabindex="props.tabindex" />

        <i v-if="props.searchIcon" class="form-icon far fa-magnifying-glass"></i>

        <i v-if="props.loading" class="form-icon loading"></i>
        <i v-else-if="props.clearable" class="form-icon far fa-xmark c-hand" @click="clear()"></i>
    </div>
</template>
