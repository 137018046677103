<template>
    <div>
        <ContextBar :title="$t('main.workflows')" :buttonText="$t('context.create_workflows')"
            buttonAbility="Use global settings" buttonRoute="workflows.create">
            <router-link tag="button" class="btn btn-order" :to="{ name: 'workflows.order' }" exact>
                <i class="far fa-mobile mr-2"></i>
                <span>{{ $t('devices.change_order_for_workflows_in_app') }}</span>
            </router-link>

            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="workflows" :columns="columns" :parameters="parameters" sort="recent_run_submit_date_time"
            :key="refresh">
            <template #column(name)="{ item }">
                <router-link :to="{ name: 'workflows.show', params: { id: item.id } }" class="table-link">
                    {{ item.name }}
                </router-link>
            </template>
            <template #column(times_executed)="{ item }">
                <router-link :to="{ name: 'workflows.show', params: { id: item.id } }">
                    <span class="chip chip-lg">
                        <i class="far fa-circle-check"></i>
                        <span class="ml-2">{{ item.times_executed }}</span>
                    </span>
                </router-link>
            </template>
            <template #column(recent_run_submit_date_time)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(expand)="{ item }">
                <button class="btn" @click="onExpandRow(item)">
                    <i class="far fa-fw" :class="[item.expanded ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                </button>
            </template>
            <template #column(actions)="{ item }">
                <Dropdown class="dropdown-right" v-if="$store.getters.can('Use global settings')">
                    <div class="menu-item">
                        <router-link :to="{ name: 'workflows.edit', params: { id: item.id } }">
                            {{ $t('main.edit') }}
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <a href="#" @click.prevent="duplicateWorkflow(item)">{{ $t('main.duplicate') }}</a>
                    </div>
                    <div class="menu-item">
                        <a href="#" class="text-error" @click.prevent="deleteWorkflow(item)">{{ $t('main.delete') }}</a>
                    </div>
                </Dropdown>
            </template>

            <template #expanded="{ item }">
                <div class="workflows-table-step bg-gray" v-for="(step, i) in item.steps" :key="step.id">
                    <figure class="avatar" :data-initial="i + 1"></figure>
                    <figure class="avatar bg-dark">
                        <i class="fa-fw text-small" :class="[typeToIcon[step.type]]"></i>
                    </figure>
                    <div class="text-bold">{{ step.description }}{{ step.allow_skip ? '' : '*' }}</div>
                    <div v-if="step.form" class="chip">{{ step.form.title }}</div>
                    <div v-if="step.field" class="chip">{{ step.field.name }}</div>
                </div>
            </template>
        </DataTable>

        <router-view @refresh="refresh++" @duplicateWorkflow="duplicateWorkflow"
            @deleteWorkflow="deleteWorkflow"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {
            refresh: 0,
            search: this.$route.query.search,

            columns: [
                { name: 'name', th: 'main.title', sortable: true, class: 'mw-250' },
                { name: 'times_executed', th: 'workflows.times_executed', sortable: true },
                { name: 'recent_run_submit_date_time', th: 'workflows.last_executed_at', sortable: true },
                { name: 'expand', min: true },
                { name: 'actions', min: true },
            ],

            typeToIcon: {
                snap: 'fas fa-camera',
                document: 'far fa-file-alt',
                QRtags: 'fas fa-qrcode',
                addField: 'fas fa-edit',
                galleryPicker: 'fas fa-grip-horizontal',
                licensePlateScanner: 'fas fa-grip-lines',
                form: 'fas fa-list-alt',
                barcodeTag: 'fas fa-barcode',
                containerTag: 'fas fa-columns',
                serial_snapper: 'fas fa-columns',
                serialBarcode: 'fas fa-barcode',
                shareDL: 'fas fa-share',
                video: 'fas fa-binoculars',
                seal: 'fas fa-binoculars',
            },
        }
    },

    computed: {
        parameters() {
            return { search: this.search || undefined }
        },
    },

    methods: {
        async onExpandRow(item) {
            item.expanded = !item.expanded

            if (!item.loaded) {
                const { data: workflow } = await this.$axios.get(`workflows/${item.id}`)
                Object.assign(item, workflow)
                item.loaded = true
            }
        },

        deleteWorkflow(workflow) {
            this.$dialog.danger(async () => {
                await this.$axios.delete(`workflows/${workflow.id}`)

                this.$router.push({ name: 'workflows' })
                this.$toast(this.$root.$t('i.delete', { i: workflow.name }))
                this.refresh++
            })
        },

        duplicateWorkflow(workflow) {
            this.$dialog.confirm(async () => {
                const { data } = await this.$axios.get(`workflows/${workflow.id}`)

                const duplicated = JSON.parse(JSON.stringify(data))
                duplicated.name = duplicated.name + ' (Copy)'
                duplicated.steps.forEach(step => delete step.id)

                const { data: duplicatedWorkflow } = await this.$axios.post('workflows', duplicated)

                this.$router.push({ name: 'workflows.edit', params: { id: duplicatedWorkflow.id } })
                this.$toast(this.$root.$t('i.create', { i: duplicatedWorkflow.name }))
                this.refresh++
            }, { title: this.$t('workflows.duplicate_this_workflow') })
        },
    },
}
</script>
