<script setup>
import { useAttrs, defineAsyncComponent } from 'vue'

const attrs = useAttrs()
const model = defineModel()

const TextEditor = defineAsyncComponent(() => import('./TextEditor.vue'))
</script>

<template>
    <Suspense>
        <TextEditor v-model="model" v-bind="attrs" />
        <template #fallback>
            <div class="text-editor ghost" style="height: 90px"></div>
        </template>
    </Suspense>
</template>
