export const getFormFieldAnswer = (field, answers) => {
    const answer = answers.find(a => a.form_field_id === field.id)

    if (!answer) return

    const value = answer.value

    // add thumbnail to field to display in form
    if (field.type === 'snap' || field.type === 'drawing') {
        field.image_thumb_sm = answer.image_thumb_sm
    }

    // for type single_select: parse db string to array
    if (field.type === 'single_select' && value.startsWith('[') && value.endsWith(']')) {
        return JSON.parse(value)
    }

    return value
}

export const transformFormFields = (fields, answers, displayEmptyValues) => {
    const filteredFields = []
    const nonFillableTypes = ['header', 'paragraph', 'separator']

    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = getFormFieldAnswer(field, answers)

        if (!field.show_in_platform) continue

        // check if answer is an array (multi-select) 
        field.value = Array.isArray(value) ? value.join(', ') : value

        const fieldIsEmpty = [undefined, ''].includes(field.value)
        const fieldIsFillable = !nonFillableTypes.includes(field.type)

        // skip fields that are deleted & empty
        if (field.deleted_at && fieldIsEmpty) continue

        // when displayEmptyValues is set to false, we want to skip fillable empty fields
        if (!displayEmptyValues && fieldIsEmpty && fieldIsFillable) continue

        filteredFields.push(field)
    }

    return filteredFields
}