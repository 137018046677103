<template>
    <div>
        <ContextBar :title="$t('main.fields')" :buttonText="$t('context.create_fields')"
            buttonAbility="Use global settings" buttonRoute="settings.fields.create">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <DataTable endpoint="fields" :columns="columns" :parameters="parameters" sort="created_at" :key="refresh">
            <template #context-left>{{ $t('settings.fields_can_be_added_to_files') }}</template>
            <template #column(name)="{ item }">
                <router-link class="field" :to="{ name: 'settings.fields.edit', params: { field_id: item.id } }">
                    <div class="field-color" :style="{ backgroundColor: item.color }"></div>
                    <div class="text-bold text-dark">{{ item.name }}</div>
                </router-link>
            </template>
            <template #column(location_id)="{ item }">
                <span v-if="item.location_id">
                    <router-link :to="{ name: 'settings.teams.show', params: { id: item.location_id } }">
                        {{ getLocationName(item.location_id) }}
                    </router-link>
                </span>
                <span v-else>{{ $t('fields.all_locations') }}</span>
            </template>
            <template #column(type)="{ value }">{{ $t(`fields.${value}`) }}</template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(auto_tag)="{ value }">
                <i v-if="value" class="fas fa-check"></i>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import { fetchLocations } from '~/helpers/locations.js'

export default {
    data() {
        return {
            refresh: 0,
            search: this.$route.query.search,

            locations: [],

            columns: [
                { name: 'name', th: 'fields.field', sortable: true },
                { name: 'location_id', th: 'main.locations', sortable: true },
                { name: 'type', th: 'fields.type', sortable: true },
                { name: 'created_at', th: 'main.created', sortable: true },
                { name: 'auto_tag', th: 'fields.add_to_new_files', sortable: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
            }
        },
    },

    async mounted() {
        this.locations = await fetchLocations(this.$axios)
    },

    methods: {
        getLocationName(locationId) {
            return this.locations.find(l => l.id === locationId)?.location
        },
    },
}
</script>
