export default {
    beforeMount(el, binding) {
        const NESTED_SELECTORS = [
            '.modal-custom',
            '.sidepanel-wrapper',
            '.vs__dropdown-menu',
            '.dp__outer_menu_wrap',
        ]

        let mouseDownOutsideElement = false

        const isAbleToClose = () => {
            const hasDialogOpen = document.querySelector('.modal-dialog') !== null
            const isDialog = el.parentElement.classList.contains('modal-dialog')

            if (hasDialogOpen && !isDialog) return false

            return !NESTED_SELECTORS.some(selector => el.querySelector(selector) !== null)
        }

        const isMouseOutsideElement = event => !(el === event.target || el.contains(event.target))

        const handleMouseDown = event => {
            const clickOnScrollbar = (window.innerWidth - event.clientX) < 15

            mouseDownOutsideElement = isMouseOutsideElement(event) && !clickOnScrollbar && isAbleToClose()
        }

        const handleMouseUp = event => {
            if (mouseDownOutsideElement && isMouseOutsideElement(event)) {
                binding.value(event)
            }

            mouseDownOutsideElement = false
        }

        const handleKeyUp = event => {
            if (event.key !== 'Escape' || !isAbleToClose()) return

            binding.value(event)
        }

        document.addEventListener('keyup', handleKeyUp)
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('mousedown', handleMouseDown)

        el._cleanup = () => {
            document.removeEventListener('keyup', handleKeyUp)
            document.removeEventListener('mouseup', handleMouseUp)
            document.removeEventListener('mousedown', handleMouseDown)
        }
    },

    unmounted(el) {
        el._cleanup()
    },
}