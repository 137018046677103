<template>
	<Toast />
	<component :is="$store.state.layout"></component>
</template>

<script>
import Toast from './Toast.vue'
import AuthLayout from './layouts/AuthLayout.vue'
import KioskLayout from './layouts/KioskLayout.vue'
import PrintFileLayout from './layouts/PrintFileLayout.vue'
import SharedFileLayout from './layouts/SharedFileLayout.vue'
import PlatformLayout from './layouts/platform/PlatformLayout.vue'

export default {
	components: {
		Toast,
		AuthLayout,
		KioskLayout,
		PlatformLayout,
		PrintFileLayout,
		SharedFileLayout,
	},
}
</script>
