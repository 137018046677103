<template>
    <Modal :title="$t('files.history')" @close="$router.push({ name: 'files.show' })">
        <DataTable :endpoint="`files/${$route.params.id}/history`" :columns="columns">
            <template #column(id)="{ item }">{{ auditJsonToText(item) }}</template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
        </DataTable>
    </Modal>
</template>

<script>
export default {
    data: () => ({
        columns: [
            { name: 'id', th: 'main.title' },
            { name: 'created_at', th: 'fields.date' },
        ],
    }),

    methods: {
        auditJsonToText(audit) {
            const MODEL_TYPE = {
                'App\\Models\\File': 'file',
                'App\\Models\\Snap': 'snap',
                'App\\Models\\FileTag': 'field',
                'App\\Models\\FileShare': 'share',
                'App\\Models\\FormSubmit': 'submit',
            }

            const type = MODEL_TYPE[audit.auditable_type]
            let by = audit.user_id ? audit.name : 'Automatic Process'
            let value = audit.new_values.value || audit.old_values.value
            let email = audit.new_values.email || audit.old_values.email

            return this.$t(`audit.${type + '_' + audit.event}`, { by, value, email })
        },
    },
}
</script>
