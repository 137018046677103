<template>
    <div :id="`form-${item.id}`" class="timeline-item">
        <div class="timeline-left">
            <a :href="`#form-${item.id}`" class="timeline-icon">
                <i class="fas fa-clipboard-list fa-sm"></i>
            </a>
        </div>
        <div class="timeline-content">
            <div class="timeline-title">
                <span>{{ $t('timeline.form', { title: item.form.title }) }}</span>
                <span class="text-small">
                    <i class="far fa-clock mr-1"></i>
                    <DateTime :date="item.created_at" ago></DateTime>
                </span>
            </div>

            <div class="box">
                <div class="columns">
                    <div class="column col-lg-12">
                        <table class="table">
                            <tbody>
                                <template v-for="field in item.form.fields">
                                    <template v-if="field.type === 'separator'">
                                        <tr class="separator"></tr>
                                        <tr></tr>
                                    </template>
                                    <tr v-else>
                                        <td>{{ field.name }}</td>
                                        <td>
                                            <i v-if="field.type === 'checkbox'" class="far"
                                                :class="field.value == '1' ? 'fa-check-square' : 'fa-square'"></i>
                                            <template v-else-if="field.type === 'snap' || field.type === 'drawing'">
                                                <img v-if="preLoad" :src="field.image_thumb_sm"
                                                    class="img-responsive" />
                                                <img v-else v-lazy="field.image_thumb_sm" class="img-responsive c-hand"
                                                    :data-source="field.value" alt />
                                            </template>
                                            <div v-else>{{ field.value }}</div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="column col-lg-12">
                        <table class="table">
                            <tbody>
                                <tr v-if="showReference">
                                    <td>{{ $t('files.reference') }}</td>
                                    <td>
                                        <router-link :to="{ name: 'files.show', params: { id: item.file_id } }">
                                            {{ item.scan_code }}
                                        </router-link>
                                    </td>
                                </tr>
                                <tr class="date-time">
                                    <td>{{ $t('timeline.submit_date_time') }}</td>
                                    <td>
                                        <DateTime :date="item.submit_date_time"></DateTime>
                                    </td>
                                </tr>
                                <tr class="place" v-if="item.latitude">
                                    <td>{{ $t('timeline.place') }}</td>
                                    <td>
                                        <span v-if="freeFormAddress">{{ freeFormAddress }}</span>
                                        <span v-else>{{ item.latitude }} / {{ item.longitude }}</span>
                                    </td>
                                </tr>
                                <tr class="uploaded-by" v-if="!hideUploader">
                                    <td>{{ $t('timeline.uploaded_by') }}</td>
                                    <td>
                                        <router-link v-if="item.device_id"
                                            :to="{ name: 'settings.devices.show', params: { id: item.device_id } }">
                                            {{ item.nick }}
                                        </router-link>
                                        <div v-else>{{ item.nick }}</div>
                                    </td>
                                </tr>
                                <tr class="workflow-item" v-if="item.workflow_step_id">
                                    <td>{{ item.workflow_description }}</td>
                                    <td>{{ item.workflow_step_description }}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="text-right">
                            <button @click="$emit('delete', item)" class="delete-submit btn mr-2">
                                <i class="fas fa-trash"></i>
                            </button>

                            <router-link class="edit-submit btn mr-2"
                                :to="{ name: 'files.form_submit.edit', params: { id: item.file_id, submit_id: item.id } }">
                                <i class="fas fa-pencil"></i>
                            </router-link>

                            <button @click="$emit('share', item)" class="edit-submit btn"
                                :class="{ 'text-gray': !item.shared }">
                                <i class="fas fa-share-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { transformFormFields } from '~/helpers/timeline.js'

export default {
    props: {
        item: Object,
        preLoad: Boolean,
        showReference: Boolean,
        hideUploader: Boolean,
    },

    computed: {
        freeFormAddress() {
            return this.item.geocoding?.addresses[0]?.address.freeformAddress
        },
    },

    created() {
        const answers = this.item.answers
        const fields = this.item.form.fields
        const displayEmptyValues = this.item.form.display_empty_values

        // process fields and answers so that we can display them in the timeline
        this.item.form.fields = transformFormFields(fields, answers, displayEmptyValues)
    },
}
</script>
