import { ref } from 'vue'

const open = ref(false)
const options = ref({})
const callbackFunction = ref(() => { })

export class DialogError extends Error {
    constructor(message) {
        super(message)
    }
}

export const props = {
    open,
    options,
    handleConfirm: async (value) => {
        options.value.errorMessage = null

        try {
            await callbackFunction.value(value || true)
        } catch (e) {
            if (e instanceof DialogError) {
                options.value.errorMessage = e.message
                return
            }

            throw e
        }

        open.value = false
    },
    handleCancel: () => {
        callbackFunction.value(false)
        open.value = false
    }
}

const createDialog = async (component, callback, opts = {}) => {
    return new Promise((resolve) => {
        open.value = true
        options.value = { ...opts, component }
        callbackFunction.value = async (value) => {
            if (value && callback) await callback(value)

            resolve(value)
        }
    })
}

export default {
    info: (cb, opts = {}) => createDialog('InfoDialog', cb, opts),
    danger: (cb, opts = {}) => createDialog('DangerDialog', cb, opts),
    confirm: (cb, opts = {}) => createDialog('ConfirmDialog', cb, opts),
}