<template>
    <div class="kiosk" :style="{ backgroundImage }">
        <div class="navbar-background">
            <div class="navbar-wrapper">
                <header class="navbar">
                    <section class="navbar-section">
                        <router-link :to="{ name: 'dashboard' }">
                            <CompanyLogo :company="$store.state.user.company" defaultLogo="/images/logo.svg" />
                        </router-link>
                    </section>
                    <section class="navbar-section">
                        <LanguageDropdown class="dropdown-right" save></LanguageDropdown>
                    </section>
                </header>
            </div>
        </div>

        <div class="platform-wrapper">
            <div class="content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyLogo from '~/components/CompanyLogo.vue'

export default {
    components: { CompanyLogo },

    computed: {
        backgroundImage() {
            const tenantId = this.$store.state.user.tenant_id

            return `url(https://cargosnapstorage.blob.core.windows.net/tenant${tenantId}/public/kiosk.jpg)`
        },
    },

    async mounted() {
        await this.$store.dispatch('fetchUser')
    },
}
</script>
