<template>
    <Modal :title="$t('profile.password_change_required')">
        <PasswordChange @close="$store.dispatch('fetchUser')"></PasswordChange>
    </Modal>
</template>

<script>
import PasswordChange from '~/components/PasswordChange.vue'

export default {
    components: { PasswordChange },
}
</script>
