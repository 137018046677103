<template>
    <Modal v-if="component" v-on="closable ? { close } : {}">
        <component :is="component"></component>
    </Modal>
</template>

<script>
import Login from '~/pages/auth/login.vue'
import Register from '~/pages/auth/register.vue'

export default {
    components: { Login, Register },

    data() {
        return {
            closable: false,
            component: null,
            token: this.$route.params.token,
        }
    },

    async mounted() {
        const { data: lookup } = await this.$axios.get(`share/${this.token}/email_exists_lookup`)

        const { email, user_exists, domain_exists, modal_closable } = lookup

        const component = this.getComponentForLookup(user_exists, domain_exists)
        const query = component === 'Register' ? { email, type: 'free' } : { email }

        await this.$router.replace({ path: this.$route.fullPath, query })

        this.closable = modal_closable
        this.component = component
    },

    methods: {
        close() {
            this.component = null
        },

        getComponentForLookup(user_exists, domain_exists) {
            // // TODO add self-register when the user does not exist but the domain does

            if (user_exists) return 'Login'
            if (!domain_exists) return 'Register'
        }
    }
}
</script>