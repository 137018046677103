import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/en'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/it'
import 'dayjs/locale/es'
import 'dayjs/locale/ru'
import 'dayjs/locale/pl'
import 'dayjs/locale/tr'
import 'dayjs/locale/da'
import 'dayjs/locale/ro'
import 'dayjs/locale/lv'
import 'dayjs/locale/se'
import 'dayjs/locale/zh'

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

window.dayjs = dayjs

export function loadDayjsLocale(locale) {
    dayjs.locale(locale === 'br' ? 'pt-br' : locale)
}